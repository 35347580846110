<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">Employee-Owners</h1>
    </div>
    <div>
        <div class="table-responsive">
            <talentbridge-grid
                #projectEmployeeGrid
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                downloadFileName="employees"
                recordNamePlural="Employees"
                (gridReady)="onProjectEmployeeGridReady($event)">
            </talentbridge-grid>
        </div>
    </div>
</div>
