import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, ProtectedResourceScopes } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { b2cPolicies, msalConfig } from "./auth.config";
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from "@angular/core";
import { DecimalPipe, CurrencyPipe, DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { RouterModule, provideRouter } from "@angular/router";
import { AgGridModule } from "ag-grid-angular";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { environment } from "src/environments/environment";
import { init_app } from "src/main";
import { routes } from "./app.routes";
import { HttpErrorInterceptor } from "./shared/interceptors/httpErrorInterceptor";
import { AppInsightsService } from "./shared/services/app-insights.service";
import { DatadogService } from "./shared/services/datadog.service";
import { GlobalErrorHandlerService } from "./shared/services/global-error-handler.service";
import { BASE_PATH } from "./shared/generated/variables";
import { MatDrawerContainer, MatSidenavModule } from "@angular/material/sidenav";

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    let msalInstance = new PublicClientApplication(msalConfig);
    return msalInstance;
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>([
        [`${environment.mainAppApiUrl}/*`, b2cPolicies.apiScopes],
      ]);
      
       
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export const appConfig : ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        importProvidersFrom(
            BrowserModule, 
            RouterModule, 
            FormsModule.withConfig({
                callSetDisabledState: "whenDisabledForLegacyCode",
            }), 
            AgGridModule, 
            SelectDropDownModule, 
            MsalModule, 
            MatExpansionModule, 
            MatExpansionModule, 
            MatSnackBarModule, 
            MatChipsModule, 
            MatInputModule, 
            MatFormFieldModule, 
            MatIconModule, 
            MatMenuModule, 
            MatCardModule, 
            MatAutocompleteModule, 
            MatDialogModule, 
            MsalModule, 
            MatRadioModule, 
            MatTooltipModule, 
            MatButtonModule, 
            MsalModule, 
            MatMomentDateModule, 
            MatSelectModule, 
            MatDatepickerModule, 
            MatCheckboxModule, 
            NgxMaterialTimepickerModule, 
            MatProgressSpinnerModule, 
            MatProgressBarModule,
            MatSidenavModule
        ),
        {
            provide: BASE_PATH,
            useValue: environment.mainAppApiUrl,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [AppInsightsService, DatadogService ],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        DecimalPipe,
        CurrencyPipe,
        DatePipe,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: "outline", hideRequiredMarker: true },
        },
        provideAnimations(),
    ]
}