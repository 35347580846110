import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CurrentServiceService } from 'src/app/services/current-service.service';
import { ServiceService } from 'src/app/shared/generated/api/service.service';
import { ServiceDto } from 'src/app/shared/generated/model/service-dto';
import { ServiceUpsertDto } from 'src/app/shared/generated/model/service-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { forkJoin } from 'rxjs';
import { ServiceLineService } from 'src/app/shared/generated/api/service-line.service';
import { ServiceCategoryService } from 'src/app/shared/generated/api/service-category.service';

@Component({
  selector: 'talentbridge-service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EsaMaterialButtonComponent,
    EsaMaterialFormFieldComponent,
    FormsModule
  ]
})
export class ServiceFormComponent implements OnInit {
  @ViewChild("serviceForm", { read: NgForm }) form: NgForm;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() serviceID: number;
  @Input() service: ServiceDto;
  @Input() editMode: boolean;

  serviceUpsertDto: ServiceUpsertDto;
  selectedServiceLineID: number;

  public serviceLines: any;
  public serviceCategories: any;
  
  public serviceCategoriesFiltered: any;

  constructor(private currentServiceService: CurrentServiceService,
    private serviceService: ServiceService,
    private serviceCategoryService: ServiceCategoryService,
    private serviceLineService: ServiceLineService,    
    private alertService: AlertService,
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    forkJoin([
      this.serviceLineService.serviceLinesGet(), 
      this.serviceCategoryService.serviceCategoriesGet()])
      .subscribe(([serviceLines, serviceCategories]) => {  
        this.serviceLines = serviceLines; 
        this.serviceCategories = serviceCategories; 
        this.setForm();
      });
  }

  setForm() {
    this.serviceUpsertDto = this.currentServiceService.createServiceDto(this.service);
    this.selectedServiceLineID = this.service.ServiceCategory?.ServiceLine?.ServiceLineID;

    this.filterServiceLine();
    this.cdr.detectChanges();
  }

  saveForm(form: NgForm) {
    if(this.serviceID) {
      this.serviceService.servicesServiceIDPut(this.serviceID, this.serviceUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The service was successfully updated.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
    else {
      this.serviceService.servicesPost(this.serviceUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The service was successfully created.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }

  filterServiceLine() {
    this.serviceCategoriesFiltered = this.serviceCategories.filter((serviceCategory) => serviceCategory.ServiceLine.ServiceLineID === this.selectedServiceLineID);
    if (this.serviceCategoriesFiltered.filter((serviceCategory) => serviceCategory.ServiceCategoryID === this.serviceUpsertDto.ServiceCategoryID).length === 0) {
      this.serviceUpsertDto.ServiceCategoryID = null;
    }
  }

  getTooltip() {
    if (this.serviceUpsertDto.Name?.length > 100) {
      return "Name field must be 100 characters or less."
    }
    
    return 'You are missing required fields.';
  }
}