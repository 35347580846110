import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentQualificationService } from 'src/app/services/current-qualification.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { QualificationFormComponent } from '../../shared/qualification-form/qualification-form.component';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { QualificationUpsertDto } from 'src/app/shared/generated/model/qualification-upsert-dto';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { AsyncPipe } from '@angular/common';
import { CustomRichTextComponent } from 'src/app/shared/components/custom-rich-text/custom-rich-text.component';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { EmployeeQualificationService } from 'src/app/shared/generated/api/employee-qualification.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { QualificationAddDialog } from '../qualification-add-dialog/qualification-add-dialog.component';
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'talentbridge-qualification-detail-summary',
    templateUrl: './qualification-detail-summary.component.html',
    styleUrls: ['./qualification-detail-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CustomRichTextComponent,
        QualificationFormComponent,
        EsaMaterialButtonComponent,
        AsyncPipe,
        MatIconModule,
        MatTooltipModule,
        MatButton,
    ]
})
export class QualificationDetailSummaryComponent implements OnInit, OnDestroy {
    @ViewChild("updateQualificationForm", { read: QualificationFormComponent }) form: QualificationFormComponent;
    
    public qualification?: QualificationDto | undefined;
    public qualification$: Observable<any>;
    public currentUser: UserDto;
    canEditText: boolean;  
    public richTextTypeID : number = CustomRichTextTypeEnum.QualificationDetail;
    public editMode = false;
    public originalQualificationUpsertDto: QualificationUpsertDto;
    public userQualifications: any[] = [];

    currentUserSubscription: Subscription;
    userAddSubscription: Subscription;
    userQualsSubscription: Subscription;

    constructor(
        private currentQualificationService: CurrentQualificationService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private cdr: ChangeDetectorRef,
        private employeeQualificationService: EmployeeQualificationService,
        public dialog: MatDialog,
        private alertService: AlertService,
    ) {}

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.userQualsSubscription = this.employeeQualificationService
                .employeesUserIDQualificationsGet(this.currentUser.UserID)
                .subscribe((data) => {
                    this.userQualifications = data;
                    this.cdr.markForCheck();
                });

            this.canEditText = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);

            this.cdr.markForCheck();
        });

        this.qualification$ = this.currentQualificationService.getCurrentQualification().pipe(
            map((data) => {
                this.qualification = data;
                this.originalQualificationUpsertDto = this.currentQualificationService.createQualificationDto(data);
                this.cdr.markForCheck();
                return data;
            })
        );
    }

    ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
        this.userAddSubscription?.unsubscribe();
        this.userQualsSubscription?.unsubscribe();
    }

    refreshQualification(result: any) {
        this.editMode = false;
        this.currentQualificationService.setCurrentQualification(result);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save(result) {
        this.refreshQualification(result);
    }

    canEdit(qualification: QualificationDto): boolean {
        return this.currentQualificationService.canEditCurrentQualification(this?.currentUser, qualification) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.originalQualificationUpsertDto) === JSON.stringify(this.form.qualificationUpsertDto);
        } else {
            return true;
        }
    }

    canAddToProfile(): boolean {
        return !this.userQualifications.some((eq) => {
            return (
                eq.Qualification.QualificationID === this.qualification.QualificationID
            );
        });
    }

    public addToProfile() {
        const dialogRef = this.dialog.open(QualificationAddDialog, {
            data: {
                Qualification: this.qualification,
            },
        });

        return dialogRef.afterClosed().subscribe((dto) => {
            if (dto) {
                this.userAddSubscription = this.employeeQualificationService
                    .employeesUserIDQualificationsPost(
                        this.currentUser.UserID,
                        dto,
                    )
                    .subscribe(
                        () => {
                            this.alertService.pushAlert(
                                new Alert(
                                    `Qualification was successfully added to your profile.`,
                                    AlertContext.Success,
                                    true,
                                ),
                            );
                            this.userQualsSubscription =
                                this.employeeQualificationService
                                    .employeesUserIDQualificationsGet(
                                        this.currentUser.UserID,
                                    )
                                    .subscribe((data) => {
                                        this.userQualifications = data;
                                        this.qualification.EmployeeCount++;
                                        this.cdr.markForCheck();
                                    });
                        },
                        (error) => {
                            this.alertService.pushAlert(
                                new Alert(
                                    `There was an error adding the qualification to your profile. Please try again.`,
                                    AlertContext.Danger,
                                    true,
                                ),
                            );
                        },
                    );
            }
        });
    }
}
