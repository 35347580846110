import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { GridApi } from 'ag-grid-community';
import { Subscription } from 'rxjs';

@Component({
    selector: "clear-grid-filters-button",
    templateUrl: "./clear-grid-filters-button.component.html",
    styleUrls: ["./clear-grid-filters-button.component.scss"],
    standalone: true,
    imports: [EsaMaterialButtonComponent],
})
export class ClearGridFiltersButtonComponent {

    @Input() grid: AgGridAngular = null;

    @Output() filtersCleared = new EventEmitter();
  
    private gridReadySubscription: Subscription = Subscription.EMPTY;
    private filtersChangedSubscription: Subscription = Subscription.EMPTY;
    public gridApi: GridApi = null;
    public gridFiltersApplied: boolean = false;
  
    ngOnInit(): void {
      this.gridReadySubscription = this.grid.gridReady.subscribe(event => {
        this.gridApi = event.api;
      });
  
      this.filtersChangedSubscription = this.grid.filterChanged.subscribe(event => {
        this.gridFiltersApplied = event.api.isAnyFilterPresent();
      });
    }
  
    ngOnDestroy(): void {
      this.gridReadySubscription.unsubscribe();
      this.filtersChangedSubscription.unsubscribe();
    }
  
    public onClick() {
      this.gridApi.setFilterModel(null);
      this.filtersCleared.emit();
    }
  }
