import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { Router } from "@angular/router";
import { ColDef } from "ag-grid-community";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";
import { ServiceCategoryService } from "src/app/shared/generated/api/service-category.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";


@Component({
    selector: "talentbridge-service-category-list",
    templateUrl: "./service-category-list.component.html",
    styleUrls: ["./service-category-list.component.scss"],
    standalone: true,
    imports: [
        EsaMaterialButtonComponent,
        TalentBridgeGridComponent,
        MatIcon
    ]
})
export class ServiceCategoryListComponent implements OnInit, OnDestroy {
    private currentUser: UserDto;
    public canCreate: boolean;
    public rowData = [];

    columnDefs: ColDef[];
    userSubscription: Subscription;
    serviceCategorySubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private serviceCategoryService: ServiceCategoryService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;
                this.canCreate = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.QualificationRights, RightsEnum.Create);

                this.createColumnDefs();
                this.cdr.markForCheck();
            });
    }

    createColumnDefs() {
        this.columnDefs = [
            {
                headerName: "Service Line",
                field: "ServiceLine.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                            field: "ServiceLine.Name",
                },
                tooltipField: "ServiceLine.Name",
            },
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ServiceCategoryID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: (params) => {
                    return {
                            component: LinkRendererComponent,
                            params: { inRouterLink: "./" },
                        };
                },
                filterValueGetter: function (params: any) {
                    return params.data.Name;
                },
                comparator: function (linkA: any, linkB: any) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 3,
                tooltipField: "$NameAndDescription",
                wrapText: true,
            },
        ];
    }

    onServiceCategoriesGridReady(gridEvent) {
        gridEvent.api.showLoadingOverlay(); 
        
        this.serviceCategorySubscription = this.serviceCategoryService
        .serviceCategoriesGet()
        .subscribe((serviceCategories) => {
            this.rowData = serviceCategories.map((sc) => {
                sc["$NameAndDescription"] = sc.Description ? `${sc.Name}: ${sc.Description}` : sc.Name;
                return sc;
            });
            gridEvent.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    navigateToServiceCategoryCreatePage() {
        this.router.navigateByUrl("service-categories/create");
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.serviceCategorySubscription?.unsubscribe();
    }
}
