<div class="list-view">
  <div class="page-header">
    <div class="actions-bar flex-between">
      <h1 class="page-title">Custom Pages</h1>
      @if (canCreate) {
        <esa-material-button
          type="primary"
          icon="add"
          label="Custom Page"
          aria-label="Add Custom Page"
          (click)="addPage()"
        ></esa-material-button>
      }
    </div>

    <div class="copy copy-2">
      <custom-rich-text
        [customRichTextTypeID]="richTextTypeID"
        [userCanEdit]="canEdit"
        editorId="customPageText"
      ></custom-rich-text>
    </div>
  </div>

  <div class="table-responsive">
    
    <talentbridge-grid
      #pageGrid
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      downloadFileName="customPages"
      recordNamePlural="Custom Pages"
      [pagination]="true"
      [sizeColumnsToFitGrid]="true"
      (gridReady)="onGridReady($event)">
  </talentbridge-grid>
  </div>
</div>
