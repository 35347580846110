import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentServiceCategoryService } from 'src/app/services/current-service-category.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { ConfirmDialog } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ServiceCategoryService } from 'src/app/shared/generated/api/service-category.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { ServiceCategoryDto } from 'src/app/shared/generated/model/service-category-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'talentbridge-service-category-detail',
  templateUrl: './service-category-detail.component.html',
  styleUrls: ['./service-category-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    RouterLinkActive,
    RouterLink,
    RouterOutlet,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    AsyncPipe,
    MatButton
  ]
})
export class ServiceCategoryDetailComponent implements OnInit, OnDestroy {
  public serviceCategory$: Observable<any>;
  public serviceCategory?: ServiceCategoryDto | undefined;

  public currentUser: UserDto;
  public editMode: boolean = false;
  public serviceCategorySideNav: any[] = [];

  editViewButtonClicked: Subscription;
  user: Subscription;
  deleteSubscription: Subscription;

  constructor(
    private serviceCategoryService: ServiceCategoryService,
    private currentServiceCategoryService: CurrentServiceCategoryService,
    private authenticationService: AuthenticationService,
    private editViewEventService: EditViewEventService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const serviceCategoryID = parseInt(this.route.snapshot.paramMap.get("id"));
    this.serviceCategorySideNav = [
      {
        Name: "Summary",
        Link: `/service-categories/${serviceCategoryID}`
      },
    ];

    this.serviceCategoryService.serviceCategoriesServiceCategoryIDGet(serviceCategoryID).subscribe((data) => {
      this.currentServiceCategoryService.setCurrentServiceCategory(data);
    },
    () => {
      this.router.navigate([""]);
    });

    this.serviceCategory$ = this.currentServiceCategoryService.getCurrentServiceCategory().pipe(
      map((data) => {
        this.serviceCategory = data;
        return data;
      })
    );

    this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
      this.currentUser = result;
      this.cdr.markForCheck();
    });

    this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
      this.editMode = result;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.currentServiceCategoryService.setCurrentServiceCategory(null);
    this.user.unsubscribe();
    this.editViewButtonClicked?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
  }

  canDelete() {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.QualificationRights, RightsEnum.Delete);
  }

  public delete() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        header: "Delete Service Category",
        text: `You are about to delete the service category "${this.serviceCategory.Name}". This action cannot be undone. Are you sure you wish to proceed?`,
      }
    });

    return dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.serviceCategoryService.serviceCategoriesServiceCategoryIDDelete(this.serviceCategory.ServiceCategoryID).subscribe(() => {
          this.alertService.pushAlert(new Alert(`Service Category was successfully deleted.`, AlertContext.Success, true));
          this.router.navigate(["/service-categories"]);
        }, error => {
          this.alertService.pushAlert(new Alert(`There was an error deleting the service category. Please try again.`, AlertContext.Danger, true));
        });
      }
    });
  }

  hasReadOnly() {
    const read = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.QualificationRights, RightsEnum.Read);
    const update = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.QualificationRights, RightsEnum.Update);
    const remove = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.QualificationRights, RightsEnum.Delete);
    const create = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.QualificationRights, RightsEnum.Create);
    if (read && !update && !remove && !create) {
      return true;
    } else {
      return false;
    }
  }

  hasChildren() {
    return (this.serviceCategory?.ServiceCount || 0) > 0;
  }
}