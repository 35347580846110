<h2 mat-dialog-title><mat-icon>public</mat-icon>Location</h2>
<mat-dialog-content>
    <p>Enter a Location</p>
    <mat-form-field>
        <mat-label>City, Postal Code, Lat/Long, etc...</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input
            matInput
            type="text"
            name="locationSearchText"
            placeholder="City, Postal Code, Lat/Long, etc..."
            [formControl]="locationSearchControl"
            [matAutocomplete]="auto"
        />

        <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selected($event)"
            [displayWith]="getName.bind(this)"
        >
            @for (loc of searchedLocations; track loc) {
                <mat-option [value]="loc">{{ loc.display_name }}</mat-option>
            }                      
        </mat-autocomplete>
    </mat-form-field>

    @if (location) {
        <mat-chip-set [attr.aria-label]="location.name" >
            <mat-chip (removed)="clear()">
                {{location.display_name}}
                <button matChipRemove aria-label="Remove">
                    <mat-icon>cancel</mat-icon>
                </button>                
            </mat-chip>  
        </mat-chip-set>
    }

    <mat-form-field>
        <mat-label>Distance</mat-label>
        <mat-select [(value)]="locationDistance">
            @for (dist of distanceOptions; track $index) {
                <mat-option [value]="dist.value">
                    {{ dist.display }}
                </mat-option>
            }
        </mat-select>
    </mat-form-field>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div>
        <a href="javascript:void(0)" (click)="clear()">Reset Filters</a>
    </div>
    <esa-material-button
        label="Cancel"
        icon="cancel"
        color="clear"
        style="margin-right: 1rem"
        (click)="closeDialog(true)"
    ></esa-material-button>
    <esa-material-button
        label="Apply"
        icon="done"
        color="primary"
        [disabled]="!location"
        (click)="closeDialog(false)"
    ></esa-material-button>
</mat-dialog-actions>
