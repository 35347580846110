import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { ServiceDto } from '../shared/generated/model/service-dto';
import { ServiceUpsertDto } from '../shared/generated/model/service-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';

@Injectable({
    providedIn: 'root'
})
export class CurrentServiceService {
    currentService$ = new BehaviorSubject<ServiceDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentService(service: ServiceDto): void {
        this.currentService$.next(service);
    }

    getCurrentService(): Observable<any> {
        return this.currentService$.asObservable();
    }

    createServiceDto(serviceDto: ServiceDto) {
        const newServiceUpsertDto = new ServiceUpsertDto({
            ServiceCategoryID: serviceDto?.ServiceCategory?.ServiceCategoryID,
            Name: serviceDto?.Name,
            Description: serviceDto?.Description,
        });
        return newServiceUpsertDto;
    }

    canEditCurrentService(currentUser: UserDto, service: ServiceDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.QualificationRights, RightsEnum.Update);
    }
}
