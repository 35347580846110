<nav>
    <a routerLink="/" routerLinkActive="active" class="site-logo">
        <img src="assets/main/logos/talentbridge_logo.svg" alt="TalentBridge" />
    </a>

    <p style="margin-top: 1rem;">Our Talent</p>
    <ul>
        @if (userCanViewEmployees) {
            <li class="nav-item">
                <a
                    routerLink="/employees"
                    class="nav-link"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined">badge</mat-icon
                    >Employee-Owners
                </a>
            </li>
        }

        @if (userCanViewQualifications) {
            <li class="nav-item">
                <a
                    routerLink="/qualifications"
                    class="nav-link"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined"
                        >military_tech</mat-icon
                    >Skills & Qualifications
                </a>
            </li>
        }
    </ul>

    <p>Our Work</p>
    <ul>
        @if (userCanViewProjects) {
            <li class="nav-item">
                <a
                    routerLink="/projects"
                    class="nav-link"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined">eco</mat-icon
                    >Projects
                </a>
            </li>
        }

        @if (userCanManageDashboard) {
            <li class="nav-item">
                <a
                    routerLink="/service-lines"
                    class="nav-link"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined"
                        >view_timeline</mat-icon
                    >Service Lines
                </a>
            </li>
        }
        @if (userCanManageDashboard) {
            <li class="nav-item">
                <a
                    routerLink="/service-categories"
                    class="nav-link"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined"
                        >category</mat-icon
                    >Service Categories
                </a>
            </li>
        }
        @if (userCanManageDashboard) {
            <li class="nav-item">
                <a
                    routerLink="/services"
                    class="nav-link"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined"
                        >design_services</mat-icon
                    >Services
                </a>
            </li>
        }
        @if (userCanViewClients) {
            <li class="nav-item">
                <a
                    routerLink="/clients"
                    class="nav-link"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined"
                        >account_balance</mat-icon
                    >Clients
                </a>
            </li>
        }
    </ul>

    <p>Workspace</p>
    <ul>
        @if (userCanViewProjects) {
            <li class="nav-item">
                <a
                    class="nav-link disabled"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined"
                        >lightbulb</mat-icon
                    >My Proposals
                </a>
            </li>
        }
        @if (userCanViewProjects) {
            <li class="nav-item">
                <a
                    class="nav-link disabled"
                    role="button"
                    routerLinkActive="active"
                >
                    <mat-icon fontSet="material-icons-outlined">add</mat-icon
                    >Create a Proposal
                </a>
            </li>
        }
    </ul>

    <ul class="utilities">
        @if (currentUser) {
            @if (userCanViewEmployees) {
                <li class="nav-item">
                    <a
                        routerLink="/employees/{{ this.currentUser?.UserID }}"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >account_circle</mat-icon
                        >Profile
                    </a>
                </li>
            }
            @if (userCanManageDashboard) {
                <li class="nav-item">
                    <a
                        routerLink="/manage"
                        class="nav-link"
                        role="button"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >tune</mat-icon
                        >Admin
                    </a>
                </li>
            }
            <!-- TODO: Do we need custom pages? -->
            <!-- <li class="nav-item dropdown" routerLinkActive="active" *ngIf="viewPages.length > 0">
      <a href="javascript:void(0);" [dropdownToggle]="viewToggle" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        View
      </a>
      <div #viewToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a *ngFor="let viewPage of viewPages" routerLink="/custom-pages/{{ viewPage.CustomPageVanityUrl }}" class="dropdown-item">
          {{ viewPage.DisplayName }}
        </a>
      </div>
    </li> -->
            <li class="user-button" data-cy="userName">
                <a
                    class="user-button__link"
                    routerLink="/employees/{{ this.currentUser?.UserID }}"
                >
                    <img
                        class="user-button__image"
                        src="{{ mainAppApiUrl }}/users/{{
                            currentUser.UserID
                        }}/photoThumbnail?uid={{
                            currentUser.UserGuid
                        }}&secure={{ currentUser.FileAccessToken }}"
                    />
                    <span class="user-button__name">{{
                        currentUser?.LoginName
                    }}</span>
                </a>
                <button
                    class="user-button__menu"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="User Menu"
                >
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <a
                        mat-menu-item
                        href="javascript:void(0);"
                        (click)="logout()"
                        ><mat-icon>logout</mat-icon
                        >{{
                            userBeingImpersonated
                                ? "Stop Impersonating"
                                : "Sign Out"
                        }}</a
                    >
                    <a
                        mat-menu-item
                        routerLink="/help"
                        class="nav-link"
                        routerLinkActive="active"
                    >
                        <mat-icon fontSet="material-icons-outlined"
                            >help</mat-icon
                        >Help
                    </a>
                </mat-menu>
            </li>
        } @else {
            <li routerLinkActive="active" class="user-button">
                <a
                    href="javascript:void(0);"
                    (click)="login()"
                    routerLinkActive="active"
                    class="nav-link"
                    data-cy="signIn"
                    ><mat-icon>login</mat-icon><span>Sign In</span></a
                >
            </li>
        }
    </ul>
</nav>
