<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Labels and Definitions</h1>
        </div>

        <div class="copy copy-2">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
                [userCanEdit]="canEditText"
                editorId="fieldDefinitionPageText"
            ></custom-rich-text>
        </div>
    </div>

    <div class="table-responsive">
        <talentbridge-grid
            #fieldDefinitionsGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="fieldDefinitions"
            recordNamePlural="Field Definitions"
            [pagination]="true"
            [sizeColumnsToFitGrid]="true"
            (gridReady)="onGridReady($event)">
        </talentbridge-grid>
    </div>
</div>
