<h2 mat-dialog-title><mat-icon>military_tech</mat-icon>Skills & Qualifications</h2>
<mat-dialog-content>
    <p>Add a Skill or Qualification</p>
    @if (qualifications && experienceLevels) {
        <talentbridge-qualification-autocomplete
            #QualificationAutocomplete
            [editMode]="true"
            [qualifications]="qualifications"
            [experienceLevels]="experienceLevels"
            [employeeQualificationDtos]="inputQualificationDtos"
            (qualificationAdded)="qualificationAdded($event)"
            (qualificationRemoved)="qualificationRemoved($event)"
        ></talentbridge-qualification-autocomplete>
    }
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div>
        <a href="javascript:void(0)" (click)="clearDialog()">Reset Filters</a>
    </div>
    <esa-material-button
        label="Cancel"
        icon="cancel"
        color="clear"
        style="margin-right: 1rem"
        (click)="closeDialog(true)"
    ></esa-material-button>
    <esa-material-button
        label="Apply"
        icon="done"
        color="primary"
        [disabled]="filteredQualificationDtos.length === 0"
        (click)="closeDialog(false)"
    ></esa-material-button>
</mat-dialog-actions>
