import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, EventEmitter, Input, model, Output, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { EmployeeQualificationSummaryDto } from 'src/app/shared/generated/model/employee-qualification-summary-dto';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ExperienceLevelDto } from 'src/app/shared/generated/model/experience-level-dto';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'talentbridge-qualification-autocomplete',
    templateUrl: './qualification-autocomplete.component.html',
    styleUrls: ['./qualification-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialButtonComponent,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatLabel,
        MatSelect,
        MatInputModule,
        RouterLink
    ]
})
export class QualificationAutoCompleteComponent {

    selectedQualification: QualificationDto;
    selectedExperienceLevel: ExperienceLevelDto = null;

    @Input() editMode: boolean = false;
    @Input() qualifications: QualificationDto[];
    @Input() employeeQualificationDtos: EmployeeQualificationSummaryDto[] = [];
    @Input() experienceLevels: ExperienceLevelDto[] = [];
    @Output() qualificationAdded = new EventEmitter<EmployeeQualificationSummaryDto>();
    @Output() qualificationRemoved = new EventEmitter<EmployeeQualificationSummaryDto>();

    _hierarchyContainsText(q: QualificationDto, currentQualification: any): boolean {
        var qualName = "";
        if (currentQualification.toLowerCase) { //this is a string, sometimes it's the object
            qualName = currentQualification.toLowerCase();
        }
        else {
            return true;
        }
        
        return q.Name.toLowerCase().includes(qualName)
            || q.Service.Name.toLowerCase().includes(qualName)
            || q.Service.ServiceCategory.Name.toLowerCase().includes(qualName)
            || q.Service.ServiceCategory.ServiceLine.Name.toLowerCase().includes(qualName);
    }

    // Signals used for autoComplete
    readonly currentQualification = model('');
    readonly filteredQualifications = computed(() => {
        const currentQualification = this.currentQualification();
        var filteredQuals = currentQualification
            ? this.qualifications.filter(q => (this._hierarchyContainsText(q, currentQualification)) && !this.employeeQualificationDtos.some(eq => eq.Qualification.QualificationID === q.QualificationID))
            : this.qualifications.filter(q => !this.employeeQualificationDtos.some(eq => eq.Qualification.QualificationID === q.QualificationID)).slice();

        var groupedQuals: any[] = [];

        //group into service line, service category, service, qualifications... flipping the hierarchy
        filteredQuals.forEach((q) => {
            var serviceLine = q.Service.ServiceCategory.ServiceLine.Name;
            var serviceCategory = q.Service.ServiceCategory.Name;
            var service = q.Service.Name;

            var sl = groupedQuals.find(g => g.ServiceLine === serviceLine);
            if (!sl) {
                sl = { ServiceLine: serviceLine, ServiceCategories: [] };
                groupedQuals.push(sl);
            }
            var sc = sl.ServiceCategories.find(g => g.ServiceCategory === serviceCategory);
            if (!sc) {
                sc = { ServiceCategory: serviceCategory, Services: [] };
                sl.ServiceCategories.push(sc);
            }
            var s = sc.Services.find(g => g.Service === service);
            if (!s) {
                s = { Service: service, Qualifications: [] };
                sc.Services.push(s);
            }
            s.Qualifications.push(q);
        });

        return groupedQuals;
    });

    constructor(
        private cdr: ChangeDetectorRef,
    ) { }

    add(): void {
        if (!this.selectedQualification) {
            return;
        }
        if (this.selectedQualification.QualificationType.RequiresExperienceLevel && !this.selectedExperienceLevel) {
            return;
        }

        var dto = {
            Qualification: this.selectedQualification,
            ExperienceLevel: this.selectedExperienceLevel
        }
        this.employeeQualificationDtos.push(dto);
        
        this.qualificationAdded.emit(dto);

        this.selectedQualification = null;
        this.selectedExperienceLevel = this.experienceLevels[0];
        this.currentQualification.set('');

        this.cdr.markForCheck();
    }

    remove(qualification: EmployeeQualificationSummaryDto): void {
        
        if (qualification) {
            this.employeeQualificationDtos.splice(this.employeeQualificationDtos.indexOf(qualification), 1);
            
            this.employeeQualificationDtos = [...this.employeeQualificationDtos];  
            this.currentQualification.set(' ');
            this.currentQualification.set('');  //this forces the computed to re-run, needs a new value apparently
            this.qualificationRemoved.emit(qualification);
        }
        
        this.cdr.markForCheck();
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.selectedQualification = this.qualifications.find(q => q.QualificationID === event.option.value.QualificationID)
        this.selectedExperienceLevel = this.experienceLevels[0];
    }

    getName(qualification: QualificationDto) {
        return qualification?.Name;
    }

    clear() {
        this.selectedQualification = null;
        this.selectedExperienceLevel = this.experienceLevels[0];
        this.employeeQualificationDtos = [];
        this.currentQualification.set('');
        this.cdr.markForCheck();
    }
}
