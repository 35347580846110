<div class="detail-view">
    @if (serviceCategory$ | async; as cat) {
        <div class="page-header has-actions-bar">
            <h1 class="page-title">Summary</h1>
            <div class="actions-bar">
                @if (canEdit(cat)) {
                    <esa-material-button
                        class="secondary-outline-button"
                        icon="edit"
                        label="Edit"
                        (click)="enableEditMode()"
                    >
                    </esa-material-button>
                }
            </div>
        </div>

        <talentbridge-service-category-form
            #updateServiceCategoryForm
            [serviceCategoryID]="cat.ServiceCategoryID"
            [serviceCategory]="cat"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"
        >
        </talentbridge-service-category-form>
    }
</div>
