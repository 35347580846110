import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { debounceTime, distinctUntilChanged, filter, startWith, Subscription, switchMap } from "rxjs";
import { GeoLocationService } from "src/app/services/geolocation.service";

@Component({
    selector: "talentbridge-location-filter-dialog",
    templateUrl: "location-filter-dialog.component.html",
    styleUrls: ["./location-filter-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        EsaMaterialButtonComponent,

        FormsModule,
        MatIconModule,
        MatChipsModule,

        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelect
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationFilterDialog {
    valueSearchSub: Subscription;
    locationSearchControl = new FormControl('');
    searchedLocations: any[] = [];
    locationDistance: number = -1;
    location: any;
    distanceOptions = [
        { value: -1, display: "Any distance" },
        { value: 25, display: "< 25 Miles" },
        { value: 50, display: "< 50 Miles" },
        { value: 100, display: "< 100 Miles" }
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<LocationFilterDialog>,
        private geolocationService: GeoLocationService,
        private cdr: ChangeDetectorRef
    ) { 
        this.location = data.location;
        this.locationDistance = data.distance;

        this.valueSearchSub = this.locationSearchControl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(1000),
                distinctUntilChanged(),
                filter((value) => value.toString() !== "[object Object]" && value.toString().trim() !== ""),
                switchMap((value) => this.geolocationService.searchLocation(value))
            ).subscribe((results) => {  
                this.searchedLocations = results;
                this.cdr.markForCheck();
            });
    }

    closeDialog(cancel: boolean) {
        this.valueSearchSub?.unsubscribe();

        if (cancel) {
            this.dialogRef.close(null);
            return;
        }

        var output = {
            location: this.location,
            distance: this.locationDistance
        };
        
        this.dialogRef.close(output);
    }
    
    selected(event: MatAutocompleteSelectedEvent): void {
        this.location = event.option.value;
    }
        
    getName(location: any) {
        return location?.display_name;
    }

    clear() {
        this.location = null;
        this.locationSearchControl.setValue('');
        this.cdr.markForCheck();
        var output = {
            location: null,
            distance: null
        };
        this.dialogRef.close(output);
    }
}