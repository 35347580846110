<div class="site">
    @if (qualification$ | async; as q) {
        <div class="dashboard">
            <aside class="sidebar">
                <div class="sidebar-header">
                    <h4 class="sidebar-header__subtitle">
                        Skills & Qualifications
                    </h4>
                    <h3 class="sidebar-header__title">
                        <mat-icon style="vertical-align: bottom" fontSet="material-icons-outlined">military_tech</mat-icon>
                        {{ q.Name }}
                    </h3>
                </div>

                <div class="sidebar-content">
                    <div class="sidebar-content__header">
                        <h4 class="sidebar-content__title">
                            Skills & Qualifications Info
                        </h4>
                    </div>

                    <ul class="sidebar-nav">
                        @for (item of qualificationSideNav; track item) {
                            <li class="sidebar-item">
                                <a [routerLink]="item.Link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="sidebar-link">{{ item.Name }}</a>
                            </li>
                        }
                    </ul>
                </div>

                <div class="sidebar-footer">
                    @if (currentUser && canDelete()) {
                        <div
                            matTooltip="Save or cancel edits to access options."
                            [matTooltipDisabled]="!editMode">
                            <button
                                mat-raised-button
                                #profileMenuTrigger="matMenuTrigger"
                                [matMenuTriggerFor]="profile"
                                class="primary-button"
                                [disabled]="editMode">
                                <mat-icon>expand_more</mat-icon>&nbsp;Options
                            </button>
                            <mat-menu #profile="matMenu" [overlapTrigger]="false" >
                                @if (canDelete()) {
                                    <button mat-menu-item (click)="delete()">
                                        <mat-icon>delete</mat-icon>
                                        <span>Delete</span>
                                    </button>
                                }
                            </mat-menu>
                        </div>
                    }
                </div>
            </aside>
            <div class="main">
                <router-outlet #manageOutlet="outlet"></router-outlet>
            </div>
        </div>
    }
</div>
