import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentEmployeeService } from 'src/app/services/current-employee.service';
import { EmployeeDto } from 'src/app/shared/generated/model/employee-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { ColDef } from 'ag-grid-community';
import { EmployeeService } from 'src/app/shared/generated/api/employee.service';
import { environment } from "src/environments/environment";
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { TalentBridgeGridComponent } from 'src/app/shared/components/talentbridge-grid/talentbridge-grid.component';
import { LinkRendererWithImageComponent } from 'src/app/shared/components/ag-grid/link-renderer-with-image/link-renderer-with-image.component';

@Component({
    selector: 'talentbridge-employee-direct-reports-list',
    templateUrl: './employee-direct-reports-list.component.html',
    styleUrls: ['./employee-direct-reports-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        TalentBridgeGridComponent
    ]
})
export class EmployeeDirectReportsListComponent implements OnInit, OnDestroy {
    public employee: EmployeeDto;
    public currentUser: UserDto;
    public rowData: any[];
    public columnDefs: ColDef[];
    public employee$: Observable<any>;

    currentUserSubscription: Subscription;
    employeeDirectReportsSubscription: Subscription;

    constructor(
        private employeeService: EmployeeService,
        private currentEmployeeService: CurrentEmployeeService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((user) => {
            this.currentUser = user;

            this.currentEmployeeService.currentEmployee$.subscribe((employee) => {
                this.employee = employee;
                this.columnDefs = [
                    {
                        headerName: "Employee Name",
                        headerTooltip: "Employee Name",
                        valueGetter: function (params: any) {
                            return {
                                    LinkValue: params.data.UserID,
                                    LinkDisplay: params.data.FullName,
                                    ImageSrc: `${environment.mainAppApiUrl}/users/${params.data.UserID}/photoThumbnail?uid=${user.UserGuid}&secure=${user.FileAccessToken}`
                                };
                        },
                        cellRendererSelector: (params) => {
                            return {
                                    component: LinkRendererWithImageComponent,
                                    params: { inRouterLink: "/employees/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.data.FullName;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
    
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "FullName",
                    },
                    {
                        headerName: "Job Title",
                        field: "JobTitle",
                        flex: 2,
                        tooltipField: "JobTitle",
                        wrapText: true,
                    },
                    {
                        headerName: "Department",
                        field: "Department",
                        flex: 2,
                        tooltipField: "Department",
                        wrapText: true,
                    },
                    {
                        headerName: "Email",
                        field: "Email",
                        flex: 2,
                        tooltipField: "Email",
                        wrapText: true,
                    },
                    {
                        headerName: "Business Phone",
                        field: "BusinessPhone",
                        flex: 1,
                        tooltipField: "BusinessPhone",
                        wrapText: true,
                    },
                    {
                        headerName: "Mobile Phone",
                        field: "MobilePhone",
                        flex: 1,
                        tooltipField: "MobilePhone",
                        wrapText: true,
                    },
                    {
                        headerName: "Office Location",
                        field: "OfficeLocation",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "OfficeLocation",
                        },
                        tooltipField: "OfficeLocation",
                        wrapText: true,
                    },
                    {
                        headerName: "Zip Code",
                        field: "ZipCode",
                        flex: 1,
                        tooltipField: "ZipCode",
                        wrapText: true,
                    },
                ];
                
                this.refreshData();
            });
        });
    }

    onEmployeeDirectReportsGridReady(event) {
        event.api.hideOverlay();
    }
    
    refreshData() {
        if (this.employee) {
            this.employeeDirectReportsSubscription = this.employeeService.employeesUserIDDirectReportsGet(this.employee.UserID).subscribe((result) => {
                this.rowData = result;
                    
                this.cdr.markForCheck();
            });
        }
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.currentUserSubscription?.unsubscribe();
        this.employeeDirectReportsSubscription?.unsubscribe();
    }
}
