import { AsyncPipe } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import {
    ActivatedRoute,
    Router,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentQualificationService } from "src/app/services/current-qualification.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { ConfirmDialog } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { QualificationService } from "src/app/shared/generated/api/qualification.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { QualificationDto } from "src/app/shared/generated/model/qualification-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "talentbridge-qualification-detail",
    templateUrl: "./qualification-detail.component.html",
    styleUrls: ["./qualification-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        RouterLinkActive,
        RouterLink,
        RouterOutlet,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        AsyncPipe,
        MatButton,
    ],
})
export class QualificationDetailComponent implements OnInit, OnDestroy {
    public qualification$: Observable<any>;
    public qualification?: QualificationDto | undefined;

    public currentUser: UserDto;
    public editMode: boolean = false;
    public qualificationSideNav: any[] = [];

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;

    constructor(
        private qualificationService: QualificationService,
        private currentQualificationService: CurrentQualificationService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        const qualificationID = parseInt(
            this.route.snapshot.paramMap.get("id"),
        );
        this.qualificationSideNav = [
            {
                Name: "Summary",
                Link: `/qualifications/${qualificationID}`,
            },
            {
                Name: "Employee-Owners",
                Link: `/qualifications/${qualificationID}/employees`,
            },
        ];

        this.qualificationService
            .qualificationsQualificationIDGet(qualificationID)
            .subscribe(
                (data) => {
                    this.currentQualificationService.setCurrentQualification(
                        data,
                    );
                },
                () => {
                    this.router.navigate([""]);
                },
            );

        this.qualification$ = this.currentQualificationService
            .getCurrentQualification()
            .pipe(
                map((data) => {
                    this.qualification = data;
                    return data;
                }),
            );

        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
            });

        this.editViewButtonClicked = this.editViewEventService
            .listenForEditButtonClicked()
            .subscribe((result) => {
                this.editMode = result;
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.currentQualificationService.setCurrentQualification(null);
        this.user.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    canDelete() {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.QualificationRights,
            RightsEnum.Delete,
        );
    }

    public delete() {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Delete Qualification",
                text: `You are about to delete the qualification "${this.qualification.Name}" which is assigned to ${this.qualification.EmployeeCount} employee(s). This action cannot be undone. Are you sure you wish to proceed?`,
            },
        });

        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.qualificationService
                    .qualificationsQualificationIDDelete(
                        this.qualification.QualificationID,
                    )
                    .subscribe(
                        () => {
                            this.alertService.pushAlert(
                                new Alert(
                                    `Qualification was successfully deleted.`,
                                    AlertContext.Success,
                                    true,
                                ),
                            );
                            this.router.navigate(["/qualifications"]);
                        },
                        (error) => {
                            this.alertService.pushAlert(
                                new Alert(
                                    `There was an error deleting the qualification. Please try again.`,
                                    AlertContext.Danger,
                                    true,
                                ),
                            );
                        },
                    );
            }
        });
    }
}
