import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, EventEmitter, Input, model, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { EmployeeQualificationSummaryDto } from 'src/app/shared/generated/model/employee-qualification-summary-dto';
import { QualificationDto } from 'src/app/shared/generated/model/qualification-dto';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ExperienceLevelDto } from 'src/app/shared/generated/model/experience-level-dto';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { QualificationAddDialog } from 'src/app/pages/qualification/qualification-detail/qualification-add-dialog/qualification-add-dialog.component';
import { Subscription } from "rxjs";
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'talentbridge-employee-qualification-autocomplete',
    templateUrl: './employee-qualification-autocomplete.component.html',
    styleUrls: ['./employee-qualification-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialButtonComponent,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatLabel,
        MatSelect,
        MatInputModule,
        RouterLink,
        MatButtonModule,
    ]
})
export class EmployeeQualificationAutoCompleteComponent implements OnInit, OnChanges {

    selectedQualification: QualificationDto;
    selectedExperienceLevel: ExperienceLevelDto;
    groupedEmployeeQualifications: any[] = [];
    qualificationBeingEdited: EmployeeQualificationSummaryDto;

    @Input() editMode: boolean = false;
    @Input() qualifications: QualificationDto[];
    @Input() employeeQualificationDtos: EmployeeQualificationSummaryDto[] = [];
    @Input() experienceLevels: ExperienceLevelDto[] = [];
    @Output() qualificationAdded = new EventEmitter<EmployeeQualificationSummaryDto>();
    @Output() qualificationRemoved = new EventEmitter<EmployeeQualificationSummaryDto>();
    
    _hierarchyContainsText(q: QualificationDto, currentQualification: any): boolean {
        var qualName = "";
        if (currentQualification.toLowerCase) { //this is a string, sometimes it's the object
            qualName = currentQualification.toLowerCase();
        }
        else {
            return true;
        }
        
        return q.Name.toLowerCase().includes(qualName)
            || q.Service.Name.toLowerCase().includes(qualName)
            || q.Service.ServiceCategory.Name.toLowerCase().includes(qualName)
            || q.Service.ServiceCategory.ServiceLine.Name.toLowerCase().includes(qualName);
    }

    _groupQualificationsByHierarchy(qualifications: QualificationDto[]): any[] {
        var groupedQuals: any[] = [];

        //group into service line, service category, service, qualifications... flipping the hierarchy
        qualifications.forEach((q) => {
            var serviceLine = q.Service.ServiceCategory.ServiceLine.Name;
            var serviceCategory = q.Service.ServiceCategory.Name;
            var service = q.Service.Name;

            var sl = groupedQuals.find(g => g.ServiceLine === serviceLine);
            if (!sl) {
                sl = { ServiceLine: serviceLine, ServiceCategories: [] };
                groupedQuals.push(sl);
            }
            var sc = sl.ServiceCategories.find(g => g.ServiceCategory === serviceCategory);
            if (!sc) {
                sc = { ServiceCategory: serviceCategory, Services: [] };
                sl.ServiceCategories.push(sc);
            }
            var s = sc.Services.find(g => g.Service === service);
            if (!s) {
                s = { Service: service, Qualifications: [] };
                sc.Services.push(s);
            }
            s.Qualifications.push(q);
        });

        return groupedQuals;
    }

    _groupQualificationsByServiceLine(qualifications: QualificationDto[]): any[] {
        var groupedQuals: any[] = [];

        //group into service line, service category, service, qualifications... flipping the hierarchy
        qualifications.forEach((q) => {
            var serviceLine = q.Service.ServiceCategory.ServiceLine.Name;
            var serviceCategory = q.Service.ServiceCategory.Name;
            var service = q.Service.Name;

            var sl = groupedQuals.find(g => g.ServiceLine === serviceLine);
            if (!sl) {
                sl = { ServiceLine: serviceLine, Qualifications: [] };
                groupedQuals.push(sl);
            }
            sl.Qualifications.push({ Qualification: q, ExperienceLevel: null, ServiceCategory: serviceCategory, Service: service });
        });

        return groupedQuals;
    }

    // Signals used for autoComplete
    readonly currentQualification = model('');
    readonly filteredQualifications = computed(() => {
        const currentQualification = this.currentQualification();
        var filteredQuals = currentQualification
            ? this.qualifications.filter(q => (this._hierarchyContainsText(q, currentQualification)) && !this.employeeQualificationDtos.some(eq => eq.Qualification.QualificationID === q.QualificationID))
            : this.qualifications.filter(q => !this.employeeQualificationDtos.some(eq => eq.Qualification.QualificationID === q.QualificationID)).slice();

        var groupedQuals = this._groupQualificationsByHierarchy(filteredQuals);
        return groupedQuals;
    });


    constructor(
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
    ) { 
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.groupedEmployeeQualifications = this.getGroupedEmployeeQualifications();
    }

    ngOnInit(): void {        
        this.groupedEmployeeQualifications = this.getGroupedEmployeeQualifications();
    }

    add(): void {
        if (!this.selectedQualification) {
            return;
        }
        if (this.selectedQualification.QualificationType.RequiresExperienceLevel && !this.selectedExperienceLevel) {
            return;
        }

        var dto = {
            Qualification: this.selectedQualification,
            ExperienceLevel: this.selectedExperienceLevel
        }
        this.employeeQualificationDtos.push(dto);
        
        this.qualificationAdded.emit(dto);

        this.selectedQualification = null;
        this.selectedExperienceLevel = null;
        this.currentQualification.set('');
        this.groupedEmployeeQualifications = this.getGroupedEmployeeQualifications();

        this.cdr.markForCheck();
    }

    edit(employeeQualification: EmployeeQualificationSummaryDto) {
        this.qualificationBeingEdited = employeeQualification;

        const dialogRef = this.dialog.open(QualificationAddDialog, {
            data: {
                Qualification: this.qualificationBeingEdited.Qualification,
            },
        });

        return dialogRef.afterClosed().subscribe((dto) => {
            if (dto) {
                var newEmployeeQualificationDto = {
                    Qualification: this.qualificationBeingEdited.Qualification,
                    ExperienceLevel: this.experienceLevels.find(el => el.ExperienceLevelID === dto.ExperienceLevelID),
                }
                this.employeeQualificationDtos.push(newEmployeeQualificationDto);
                this.qualificationAdded.emit(newEmployeeQualificationDto);
                this.remove(this.qualificationBeingEdited);
            }
        });
    }

    remove(qualification: EmployeeQualificationSummaryDto): void {
        
        if (qualification) {
            var qualToRemove = this.employeeQualificationDtos.find(eq => eq.Qualification.QualificationID === qualification.Qualification.QualificationID);
            this.employeeQualificationDtos.splice(this.employeeQualificationDtos.indexOf(qualToRemove), 1);
            
            this.employeeQualificationDtos = [...this.employeeQualificationDtos];  
            this.currentQualification.set(' ');
            this.currentQualification.set('');  //this forces the computed to re-run, needs a new value apparently
            this.qualificationRemoved.emit(qualification);
            this.groupedEmployeeQualifications = this.getGroupedEmployeeQualifications();
        }
        
        this.cdr.markForCheck();
    }

    clear(): void { 
        this.employeeQualificationDtos = [];
        this.groupedEmployeeQualifications = this.getGroupedEmployeeQualifications();
        this.cdr.markForCheck();
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.selectedQualification = this.qualifications.find(q => q.QualificationID === event.option.value.QualificationID)
        this.selectedExperienceLevel = null;
    }

    getName(qualification: QualificationDto) {
        return qualification?.Name;
    }


    getGroupedEmployeeQualifications() {   
        var selectedQuals = this.qualifications.filter(q => this.employeeQualificationDtos.some(eq => eq.Qualification.QualificationID === q.QualificationID));
        var groupedQuals = this._groupQualificationsByServiceLine(selectedQuals);
        //add experience level to the qualification
        groupedQuals.forEach((sl) => {
            sl.Qualifications.forEach((q) => {
                var eq = this.employeeQualificationDtos.find(eq => eq.Qualification.QualificationID === q.Qualification.QualificationID);
                q.ExperienceLevel = eq.ExperienceLevel;
            });
        });
        return groupedQuals;
    }

}
