import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { ServiceLineDto } from '../shared/generated/model/service-line-dto';
import { ServiceLineUpsertDto } from '../shared/generated/model/service-line-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';

@Injectable({
    providedIn: 'root'
})
export class CurrentServiceLineService {
    currentServiceLine$ = new BehaviorSubject<ServiceLineDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentServiceLine(serviceLine: ServiceLineDto): void {
        this.currentServiceLine$.next(serviceLine);
    }

    getCurrentServiceLine(): Observable<any> {
        return this.currentServiceLine$.asObservable();
    }

    createServiceLineDto(serviceLineDto: ServiceLineDto) {
        const newServiceLineUpsertDto = new ServiceLineUpsertDto({
            Name: serviceLineDto?.Name,
            Description: serviceLineDto?.Description,
        });
        return newServiceLineUpsertDto;
    }

    canEditCurrentServiceLine(currentUser: UserDto, serviceLine: ServiceLineDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.QualificationRights, RightsEnum.Update);
    }
}
