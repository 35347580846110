<div class="pagination-control-panel" *ngIf="paginationValues$ | async as paginationValues">
    <button
        (click)="goToFirstPage()"
        class="control-button"
        [disabled]="paginationValues.onFirstPage">
        <i class='mat-icon material-icons mat-icon-no-color'>keyboard_double_arrow_left</i>
    </button>

    <button
        (click)="goToPreviousPage()"
        class="control-button"
        [disabled]="paginationValues.onFirstPage">
        <i class='mat-icon material-icons mat-icon-no-color'>keyboard_arrow_left</i>
    </button>

    <div class="control-text">
        Page {{ paginationValues.currentPage }} of {{ paginationValues.totalPages }}
    </div>

    <button
        (click)="goToNextPage()"
        class="control-button"
        [disabled]="paginationValues.onLastPage">
        <i class='mat-icon material-icons mat-icon-no-color'>keyboard_arrow_right</i>
    </button>

    <button
        (click)="goToLastPage()"
        class="control-button"
        [disabled]="paginationValues.onLastPage">
        <i class='mat-icon material-icons mat-icon-no-color'>keyboard_double_arrow_right</i>
    </button>
</div>
