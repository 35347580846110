@if (qualification$ | async; as qual) {
    <div class="detail-view">
        <div class="page-header has-actions-bar">
            <h1 class="page-title">Summary</h1>
            <div class="actions-bar" matTooltip="Save or cancel edits to access option." [matTooltipDisabled]="!editMode">
                @if (currentUser && canAddToProfile()) {
                    <esa-material-button
                        class="secondary-outline-button"
                        icon="add"
                        label="Add To Profile"
                        (click)="addToProfile()"
                        [disabled]="editMode"
                    ></esa-material-button>
                }
                @if (canEdit(qual)) {
                    <esa-material-button
                        style="margin-left: 1rem"
                        class="secondary-outline-button"
                        icon="edit"
                        label="Edit"
                        (click)="enableEditMode()"
                    >
                    </esa-material-button>
                }
            </div>
        </div>

        <div class="copy copy-2">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
                [userCanEdit]="canEditText"
                editorId="qualificationDetailPageText">
            </custom-rich-text>
        </div>

        <talentbridge-qualification-form
            #updateQualificationForm
            [qualificationID]="qual.QualificationID"
            [qualification]="qual"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"
        >
        </talentbridge-qualification-form>
    </div>
}
