<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">Employee-Owners</h1>
    </div>

    <mat-accordion>
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title> Qualification Employee Map</mat-panel-title>
            </mat-expansion-panel-header>
      <map #MapComponent (onMapLoad)="mapInit($event)">
        @if (map) {
          @for (e of filteredZipsWithGeoData; track $index) {
            <map-marker 
              #mapMarker
              [latitude]="e.Latitude" 
              [longitude]="e.Longitude" 
              [label]="e.ZipCode" 
              [map]="map">
              <!-- NOTE: CG: We cant select just one user for a marker here punting. (onClick)="onMarkerClick($event, e)"> -->
            </map-marker>
          } 
        }
      </map>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="table-responsive">
    <talentbridge-grid
      #EmployeeGrid
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      downloadFileName="employees"
      recordNamePlural="Employees"
      rowSelection="single"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged($event)"
      (filterChanged)="onFilterChanged($event)">
    </talentbridge-grid>
  </div>
</div>
