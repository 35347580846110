import {
    Component,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
} from "@angular/core";
import { UserService } from "src/app/shared/generated/api/user.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ColDef } from "ag-grid-community";
import { RoleEnum } from "src/app/shared/generated/enum/role-enum";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { Subscription } from "rxjs";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { LinkRendererWithImageComponent } from "src/app/shared/components/ag-grid/link-renderer-with-image/link-renderer-with-image.component";
import { environment } from "src/environments/environment";
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";


@Component({
    selector: "talentbridge-user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"],
    standalone: true,
    imports: [
        TalentBridgeGridComponent
    ],
})
export class UserListComponent implements OnInit, OnDestroy {
    private currentUser: UserDto;
    public canCreate: boolean;

    public rowData = [];
    columnDefs: ColDef[];
    users: UserDto[];
    unassignedUsers: UserDto[];

    userSubscription: Subscription;
    getUsersSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private dateColumnCreator: DateColumnCreatorService
    ) {
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;

                this.columnDefs = [
                    {
                        headerName: "Name",
                        valueGetter: function (params: any) {
                            return {
                                LinkValue: params.data.UserID,
                                LinkDisplay: params.data.FullName,
                                ImageSrc: `${environment.mainAppApiUrl}/users/${params.data.UserID}/photoThumbnail?uid=${currentUser.UserGuid}&secure=${currentUser.FileAccessToken}`
                            };
                        },
                        cellRenderer: LinkRendererWithImageComponent,
                        cellRendererParams: { inRouterLink: "/manage/users/" },
                        filterValueGetter: function (params: any) {
                            return params.data.FullName;
                        },
                        comparator: function (id1: any, id2: any) {
                            let link1 = id1.LinkDisplay;
                            let link2 = id2.LinkDisplay;
                            if (link1 < link2) {
                                return -1;
                            }
                            if (link1 > link2) {
                                return 1;
                            }
                            return 0;
                        },
                        flex: 4,
                        tooltipField: "Name",
                    },
                    {
                        headerName: "Email",
                        field: "Email",
                        flex: 4,
                        tooltipField: "Email",
                    },
                    {
                        headerName: "Role",
                        field: "Role.DisplayName",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "Role.DisplayName",
                        },
                    },
                    {
                        headerName: "Receives System Communications?",
                        field: "ReceiveSupportEmails",
                        valueGetter: function (params) {
                            return params.data.ReceiveSupportEmails ? "Yes" : "No";
                        },
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "ReceiveSupportEmails",
                        },
                        flex: 1,
                    },
                    this.dateColumnCreator.createDateColumnDef(
                        "Created",
                        "CreateDate",
                        "M/d/yyyy"
                    ),
                    {
                        headerName: "User ID",
                        valueGetter: function (params: any) {
                            return params.data.UserID;
                        },
                        filter: "agNumberColumnFilter",
                        width: 90,
                    },
                ];
                this.canCreate = this.authenticationService.hasPermission(
                    this.currentUser,
                    PermissionEnum.UserRights,
                    RightsEnum.Create
                );

                this.cdr.markForCheck();
            });
    }

    onUsersGridReady(gridEvent) {
        gridEvent.api.showLoadingOverlay();

        this.getUsersSubscription = this.userService
            .usersGet()
            .subscribe((users) => {
                this.rowData = users;
                this.users = users;

                this.unassignedUsers = users.filter((u) => {
                    return u.Role.RoleID === RoleEnum.NoAccess && u.IsActive;
                });

                gridEvent.api.hideOverlay();
            });
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.getUsersSubscription?.unsubscribe();
        this.userSubscription?.unsubscribe();
    }
}
