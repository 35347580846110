import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { ClientFormComponent } from '../../shared/client-form/client-form.component';
import { AsyncPipe } from '@angular/common';
import { CustomRichTextComponent } from 'src/app/shared/components/custom-rich-text/custom-rich-text.component';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';

@Component({
    selector: 'talentbridge-client-detail-summary',
    templateUrl: './client-detail-summary.component.html',
    styleUrls: ['./client-detail-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CustomRichTextComponent,
        ClientFormComponent,
        AsyncPipe
    ],
})
export class ClientDetailSummaryComponent implements OnInit, OnDestroy {
  @ViewChild("updateClientForm", { read: ClientFormComponent }) form: ClientFormComponent;

    public client$: Observable<any>;
    public currentUser: UserDto;
    canEditText: boolean;  
    public richTextTypeID : number = CustomRichTextTypeEnum.ClientDetail;
    public editMode = false;
    user: Subscription;

    constructor(
        private currentClientService: CurrentClientService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {   
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;

                this.canEditText = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);
            });
            
        this.client$ = this.currentClientService.getCurrentClient().pipe(
            map((data) => {
                this.cdr.markForCheck();
                return data;
            })
        );
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.user?.unsubscribe();
    }

}
