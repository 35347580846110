<form class="form grid-12" #qualificationForm="ngForm">
    @if (qualificationUpsertDto) {
        <div class="g-col-4">
            <esa-material-ff
                name="Name"
                [editMode]="editMode"
                [required]="true"
                label="Name"
                type="text"
                [(ngModel)]="qualificationUpsertDto.Name"
                [maxlength]="200"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="QualificationType"
                [editMode]="editMode"
                [required]="true"
                label="Type"
                type="dropdown"
                [options]="qualificationTypes"
                optionLabel="Name"
                optionValue="QualificationTypeID"
                [(ngModel)]="qualificationUpsertDto.QualificationTypeID"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4"></div>

        <div class="g-col-4">
            <esa-material-ff
                name="ServiceLine"
                [editMode]="editMode"
                [required]="true"
                label="Service Line"
                type="dropdown"
                [options]="serviceLines"
                optionLabel="Name"
                optionValue="ServiceLineID"
                [(ngModel)]="selectedServiceLineID"
                (ngModelChange)="filterServiceLine()"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="ServiceCategory"
                [editMode]="editMode"
                [required]="true"
                label="Service Category"
                type="dropdown"
                [options]="serviceCategoriesFiltered"
                optionLabel="Name"
                optionValue="ServiceCategoryID"
                [(ngModel)]="selectedServiceCategoryID"
                (ngModelChange)="filterServiceCategory()"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="Service"
                [editMode]="editMode"
                [required]="true"
                label="Service"
                type="dropdown"
                [options]="servicesFiltered"
                optionLabel="Name"
                optionValue="ServiceID"
                [(ngModel)]="qualificationUpsertDto.ServiceID"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-12">
            <esa-material-ff
                name="Description"
                [editMode]="editMode"
                label="Description"
                type="textarea"
                [(ngModel)]="qualificationUpsertDto.Description"
            >
            </esa-material-ff>
        </div>

        @if (editMode) {
            <div
                class="g-col-12 actions-bar actions-bar__footer"
                style="margin-top: 1rem"
            >
                <esa-material-button
                    style="margin-right: 1rem"
                    type="primary"
                    label="Save"
                    icon="save"
                    [tooltip]="
                        !qualificationForm.form.valid
                            ? getTooltip()
                            : ''
                    "
                    (click)="saveForm(form)"
                    [disabled]="!qualificationForm.form.valid"
                >
                </esa-material-button>
                <esa-material-button
                    type="clear"
                    label="Cancel"
                    icon="cancel"
                    (click)="cancelEditMode()"
                >
                </esa-material-button>
            </div>
        }
    }
</form>
