<div class="site">
    @if (serviceLine$ | async; as sl) {
        <div class="dashboard">
            <aside class="sidebar">
                <div class="sidebar-header">
                    <h4 class="sidebar-header__subtitle">Service Lines</h4>
                    <h3 class="sidebar-header__title">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >view_timeline</mat-icon
                        >
                        {{ sl.Name }}
                    </h3>
                </div>

                <div class="sidebar-content">
                    <div class="sidebar-content__header">
                        <h4 class="sidebar-content__title">
                            Service Line Info
                        </h4>
                    </div>
                    <ul class="sidebar-nav">
                        @for (item of serviceLineSideNav; track item) {
                            <li class="sidebar-item">
                                <a
                                    [routerLink]="item.Link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="sidebar-link"
                                    >{{ item.Name }}</a
                                >
                            </li>
                        }
                    </ul>
                </div>

                <div class="sidebar-footer">
                    @if (!hasReadOnly()) {
                        <div
                            matTooltip="Save or cancel edits to access options."
                            [matTooltipDisabled]="!editMode"
                        >
                            <button
                                mat-raised-button
                                #profileMenuTrigger="matMenuTrigger"
                                [matMenuTriggerFor]="profile"
                                class="primary-button"
                                [disabled]="editMode"
                            >
                                <mat-icon>expand_more</mat-icon>&nbsp;Options
                            </button>
                            <mat-menu
                                #profile="matMenu"
                                [overlapTrigger]="false"
                            >
                                @if (canDelete()) {
                                    <div
                                        matTooltip="This Service Line is associated with Service Categories; please delete the Service Categories or move them to a different Service Line before deleting."
                                        [matTooltipDisabled]="!hasChildren()"
                                    >
                                        <button
                                            mat-menu-item
                                            (click)="delete()"
                                            [disabled]="hasChildren()"
                                        >
                                            <mat-icon>delete</mat-icon>
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                }
                            </mat-menu>
                        </div>
                    }
                </div>
            </aside>
            <div class="main">
                <router-outlet #manageOutlet="outlet"></router-outlet>
            </div>
        </div>
    }
</div>
