@if (employeeQualificationDtos) {
    @if (editMode) {
        <div class="new-chips-bar" style="margin-top: 1rem">
            <mat-form-field>
                <mat-label>Qualification</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input
                    matInput
                    type="text"
                    name="currentQualification"
                    placeholder="New Qualification..."
                    [(ngModel)]="currentQualification"
                    [matAutocomplete]="auto" />

                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="selected($event)"
                    [displayWith]="getName.bind(this)">

                    @for (serviceLine of filteredQualifications(); track serviceLine) {
                        <mat-optgroup [label]="serviceLine.ServiceLine">
                            @for (serviceCategory of serviceLine.ServiceCategories; track serviceCategory) {
                                <mat-optgroup [label]="serviceCategory.ServiceCategory">
                                    @for (service of serviceCategory.Services; track service) {
                                        <mat-optgroup [label]="service.Service">
                                            @for (qual of service.Qualifications; track qual) {
                                                <mat-option [value]="qual">
                                                    {{qual.Name}}
                                                </mat-option>
                                            }
                                        </mat-optgroup>
                                    }
                                </mat-optgroup>
                            }
                        </mat-optgroup>
                    }
                </mat-autocomplete>
            </mat-form-field>

            @if (this.selectedQualification?.QualificationType.RequiresExperienceLevel) {
                <mat-form-field class="exp-level">
                    <mat-label>Experience Level</mat-label>
                    <mat-select [(value)]="selectedExperienceLevel">
                        @for (expLevel of experienceLevels; track $index) {
                            <mat-option [value]="expLevel">
                                {{ expLevel.DisplayName }}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }
            <esa-material-button
                class="add-chips"
                title="Add Qualification"
                label="Add"
                icon="add"
                (click)="add()"
                >Add
            </esa-material-button>
        </div>
    }
    @if (employeeQualificationDtos.length > 0) {
        @for (qual of groupedEmployeeQualifications; track $index) {
            <div class="section-header">
                <h3>{{qual.ServiceLine}}</h3>
            </div>
            <mat-chip-set [attr.aria-label]="qual.ServiceLine + ' Qualifications'">
                @for (emp of qual.Qualifications; track emp) {
                    <div>
                        <mat-chip>
                            <a [routerLink]="['/qualifications', emp.Qualification.QualificationID]">{{ emp.Qualification.Name }}</a>
                            @if (emp.ExperienceLevel && emp.ExperienceLevel.ExperienceLevelID) {
                                @for (_ of [].constructor(emp.ExperienceLevel.ExperienceLevelID);track _) {
                                    <mat-icon [title]="emp.ExperienceLevel.DisplayName">star_rate</mat-icon>
                                }
                            } 
                        </mat-chip>
                        <span> {{ emp.ServiceCategory }} &raquo; {{ emp.Service }}</span>  
                        
                        @if (editMode && emp.ExperienceLevel) {
                            <button mat-icon-button class="small-icon-button" [attr.aria-label]="'Edit ' + emp.Qualification.Name" (click)="edit(emp)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        }
                        @if (editMode) {
                            <button mat-icon-button class="small-icon-button" [attr.aria-label]="'Remove ' + emp.Qualification.Name" (click)="remove(emp)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        } 
                    </div>     
                }
            </mat-chip-set>
        }
    } @else {
        @if (!editMode) {
            <div class="no-results">
                <h3>
                    No Skills and Qualifications assigned yet. Click the edit
                    button above to begin adding your Skills & Qualifications.
                </h3>
            </div>
        }
    }
}
 