import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { AdminConfigurationService } from 'src/app/shared/generated/api/admin-configuration.service';
import { AdminConfigurationDto } from 'src/app/shared/generated/model/admin-configuration-dto';
import { AdminConfigurationUpsertDto } from 'src/app/shared/generated/model/admin-configuration-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
    selector: 'talentbridge-configuration-form',
    templateUrl: './configuration-form.component.html',
    styleUrls: ['./configuration-form.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialFormFieldComponent,
        EsaMaterialButtonComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationFormComponent {
  @ViewChild("configurationForm", { read: NgForm }) form: NgForm;

  @Input() adminConfiguration: AdminConfigurationDto;
  @Input() editMode: boolean;

  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  adminConfigurationUpsertDto: AdminConfigurationUpsertDto;
  
  constructor(
    private configurationService: AdminConfigurationService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.adminConfiguration) {
      this.setForm();
    }
  }

  setForm() {
    this.adminConfigurationUpsertDto = this.createAdminConfigurationUpsertDto(this.adminConfiguration);
    this.cdr.markForCheck();
  }

  saveForm(form: NgForm) {
    this.configurationService.adminConfigurationPut(this.adminConfigurationUpsertDto)
      .subscribe((result) => {
        this.adminConfiguration = result;
        this.cancelEditMode();
        this.alertService.pushAlert(new Alert("The configuration was successfully updated.", AlertContext.Success), 5000);
      });
  }

  cancelEditMode() {
    this.setForm();
    this.cancelEditModeChange.emit(true);
  }

  createAdminConfigurationUpsertDto(adminConfiguration: AdminConfigurationDto) {
    const adminConfigurationUpserDto = new AdminConfigurationUpsertDto({
      SendReminderEmail: adminConfiguration?.SendReminderEmail,
      ReminderEmailAgeInDays: adminConfiguration?.ReminderEmailAgeInDays,
      NumberOfReminderEmails: adminConfiguration?.NumberOfReminderEmails,
      NormalUsersCanEditTheirProjectProperties: adminConfiguration?.NormalUsersCanEditTheirProjectProperties,
    });
    return adminConfigurationUpserDto;
  }
}