<div class="list-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title has-icon">
            <mat-icon fontSet="material-icons-outlined">design_services</mat-icon>
            Services
        </h1>
        @if (canCreate) {
            <esa-material-button
                label="Add Service"
                icon="add"
                type="primary"
                class="secondary-outline-button"
                (click)="navigateToServiceCreatePage()"
            >
            </esa-material-button>
        }
    </div>

    <div class="table-responsive">
        <talentbridge-grid
            #servicesGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="services"
            recordNamePlural="Services"
            (gridReady)="onServicesGridReady($event)"
        >
        </talentbridge-grid>
    </div>
</div>
