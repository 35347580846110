<div class="detail-view">
    @if (service$ | async; as serv) {
        <div class="page-header has-actions-bar">
            <h1 class="page-title">Summary</h1>
            <div class="actions-bar"></div>
            @if (canEdit(serv)) {
                <esa-material-button
                    class="secondary-outline-button"
                    icon="edit"
                    label="Edit"
                    (click)="enableEditMode()"
                >
                </esa-material-button>
            }
        </div>

        <talentbridge-service-form
            #updateServiceForm
            [serviceID]="serv.ServiceID"
            [service]="serv"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"
        >
        </talentbridge-service-form>
    }
</div>
