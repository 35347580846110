import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { CurrentEmployeeService } from 'src/app/services/current-employee.service';
import { EmployeeService } from 'src/app/shared/generated/api/employee.service';
import { EmployeeDto } from 'src/app/shared/generated/model/employee-dto';
import { EmployeeUpsertDto } from 'src/app/shared/generated/model/employee-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent, EsaLabelComponent } from 'esa-material-form-field';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'talentbridge-employee-form',
    templateUrl: './employee-form.component.html',
    styleUrls: ['./employee-form.component.scss'],
    standalone: true,
    imports: [
      FormsModule,
      EsaMaterialFormFieldComponent,
      EsaMaterialButtonComponent,
      EsaLabelComponent,
      RouterLink
    ],
})
export class EmployeeFormComponent implements OnInit, OnChanges {
  @ViewChild("employeeForm", { read: NgForm }) form: NgForm;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() userID: number;
  @Input() employee: EmployeeDto;
  @Input() editMode: boolean;

  employeeUpsertDto: EmployeeUpsertDto;

  constructor(private currentEmployeeService: CurrentEmployeeService,
    private employeeService: EmployeeService,
    private alertService: AlertService) {}

  ngOnChanges(changes: SimpleChanges): void {
      if (this.employee) {
          this.setForm();
      }
  }

  ngOnInit(): void {
      if (this.employee) {
          this.setForm();
      }
  }

  setForm() {
      this.employeeUpsertDto = this.currentEmployeeService.createEmployeeDto(this.employee);
  }

  saveForm(form: NgForm) {
    this.employeeService.employeesUserIDPut(this.userID, this.employeeUpsertDto).subscribe((result) => {
      this.alertService.pushAlert(new Alert("The employee was successfully updated.", AlertContext.Success), 5000);
      this.formSubmitted.emit(result);
    });
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }

  getTooltip() {
    if (this.employeeUpsertDto.ZipCode?.length > 30) {
      return "Zip Code field must be 30 characters or less."
    } else if (this.employeeUpsertDto.PreferredPronouns?.length > 30) {
      return "Preferred Pronouns field must be 30 characters or less."
    }
    return 'You are missing required fields.';
  }
}