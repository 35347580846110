import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { ServiceCategoryDto } from '../shared/generated/model/service-category-dto';
import { ServiceCategoryUpsertDto } from '../shared/generated/model/service-category-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';

@Injectable({
    providedIn: 'root'
})
export class CurrentServiceCategoryService {
    currentServiceCategory$ = new BehaviorSubject<ServiceCategoryDto>(null);

    constructor(private authenticationService: AuthenticationService) {}

    setCurrentServiceCategory(serviceCategory: ServiceCategoryDto): void {
        this.currentServiceCategory$.next(serviceCategory);
    }

    getCurrentServiceCategory(): Observable<any> {
        return this.currentServiceCategory$.asObservable();
    }

    createServiceCategoryDto(serviceCategoryDto: ServiceCategoryDto) {
        const newServiceCategoryUpsertDto = new ServiceCategoryUpsertDto({
            ServiceLineID: serviceCategoryDto?.ServiceLine?.ServiceLineID,
            Name: serviceCategoryDto?.Name,
            Description: serviceCategoryDto?.Description,
        });
        return newServiceCategoryUpsertDto;
    }

    canEditCurrentServiceCategory(currentUser: UserDto, serviceCategory: ServiceCategoryDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.QualificationRights, RightsEnum.Update);
    }
}
