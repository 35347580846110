<div class="grid-header flex-between">
    <div class="grid-actions flex">
        <div class="global-filter">
            @if (!hideGlobalFilter) {
                <div class="field">
                    <input type="text" name="quickFilterText" [(ngModel)]="quickFilterText" placeholder="Search grid..." />
                </div>
            }
        </div>
        <ng-content select="[customGridActionsLeft]"></ng-content>
    </div>
    <div class="grid-actions flex-end">
        @if (multiSelectEnabled) {
            @if (selectedRowsCount > 0) {
                <span class="selection-info">
                    {{ selectedRowsCount }} of {{ rowData?.length }} Selected
                </span>
            }

            <button class="btn btn-sm btn-secondary" (click)="onSelectAll()" [class.disabled]="allRowsSelected">
                Select All
                @if (anyFilterPresent) {
                    <span>(filtered)</span>
                }
            </button>
            <button class="btn btn-sm btn-secondary" (click)="onDeselectAll()" [class.disabled]="selectedRowsCount < 1">
                Deselect All
                @if (anyFilterPresent) {
                    <span>(filtered)</span>
                }
            </button>
        }

        <ng-content select="[customGridActions]"></ng-content>

        <div class="clear-filters-button">
            <clear-grid-filters-button 
                [grid]="gridref"
                (filtersCleared)="onFiltersCleared()">
            </clear-grid-filters-button>
        </div>
    </div>
</div>
<div class="table-responsive">
    <ag-grid-angular 
        #gridref 
        defaultGridOptions 
        class="ag-theme-balham"
        [style.height]="height" 
        [style.width]="width" 
        [overlayLoadingTemplate]="agGridOverlay" 
        [autoSizeStrategy]="autoSizeStrategy" 
        [rowData]="rowData"
        [columnDefs]="columnDefs" 
        [defaultColDef]="defaultColDef" 
        [tooltipInteraction]="true" 
        [tooltipShowDelay]="800"
        [rowSelection]="rowSelection" 
        [suppressRowClickSelection]="suppressRowClickSelection"
        [rowMultiSelectWithClick]="rowMultiSelectWithClick" 
        [suppressRowTransform]="true" 
        [suppressMenuHide]="true"
        [pagination]="pagination" 
        [paginationPageSize]="paginationPageSize" 
        [suppressPaginationPanel]="true"        
        [doesExternalFilterPass]="doesExternalFilterPass" 
        [isExternalFilterPresent]="isExternalFilterPresent"
        [quickFilterText]="quickFilterText" 
        [getRowId]="getRowId" 
        (gridReady)="onGridReady($event)"
        (gridColumnsChanged)="onGridColumnsChanged($event)" 
        (selectionChanged)="this.onSelectionChanged($event)"        
        (filterChanged)="onFilterChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)">
    </ag-grid-angular>
    <div class="table-footer flex-between">
        @if (!hideDownloadButton) {
            <div class="download-button">
                <csv-download-button 
                    [grid]="gridref" 
                    [fileName]="downloadFileName"
                    [colIDsToExclude]="colIDsToExclude">
                </csv-download-button>
            </div>
        }
        @if (gridLoaded && pagination) {
            <pagination-controls [grid]="gridref"></pagination-controls>
        }

        <div class="row-count-data">
            Total {{ recordNamePlural }}: {{ rowData?.length | number }}

            @if (anyFilterPresent) {
                <div class="filtered-rows-count">
                    Filtered {{ recordNamePlural }}: {{ filteredRowsCount | number }}
                </div>
            }
        </div>
    </div>
</div>