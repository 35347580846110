import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from "@angular/core";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ClientService } from "src/app/shared/generated/api/client.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";
import { MatIcon } from "@angular/material/icon";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";

@Component({
    selector: "talentbridge-client-list",
    templateUrl: "./client-list.component.html",
    styleUrls: ["./client-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CustomRichTextComponent,
        TalentBridgeGridComponent,
        MatIcon
    ],
})
export class ClientListComponent implements OnInit {
    public currentUser: UserDto;
    canEditText: boolean;  
    public richTextTypeID : number = CustomRichTextTypeEnum.ClientIndex;
    public rowData = [];
    public columnDefs: ColDef[];
    
    currentUserRequest: Subscription;
    getClientsRequest: Subscription;

    constructor(
        private clientService: ClientService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.currentUserRequest = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;

                this.canEditText = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);

                this.columnDefs = [
                    {
                        headerName: "Client Name",
                        headerTooltip: "Client Name",
                        valueGetter: function (params: any) {
                            return {
                                    LinkValue: params.data.ClientID,
                                    LinkDisplay: params.data.Name,
                                };
                        },
                        cellRendererSelector: (params) => {
                            return {
                                    component: LinkRendererComponent,
                                    params: { inRouterLink: "/clients/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.data.Name;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
    
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "Name",
                    },
                    {
                        headerName: "Client Number",
                        field: "ClientNumber",
                        flex: 2,
                        tooltipField: "ClientNumber",
                        wrapText: true,
                    },
                    {
                        headerName: "Market",
                        field: "Market",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "Market",
                        },
                        tooltipField: "Market",
                    },
                    {
                        headerName: "Type",
                        field: "ClientType",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "ClientType",
                        },
                        tooltipField: "ClientType",
                    },
                    {
                        headerName: "Status",
                        field: "Status",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "Status",
                        },
                        tooltipField: "Status",
                    },
                ];
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.currentUserRequest.unsubscribe();
        this.getClientsRequest.unsubscribe();
    }

    onClientsGridReady(gridEvent) {
        gridEvent.api.showLoadingOverlay();

        this.getClientsRequest = this.clientService
            .clientsGet()
            .subscribe((results) => {
                this.rowData = results;
                gridEvent.api.hideOverlay();
                this.cdr.markForCheck();
            });
    }
}
