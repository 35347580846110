//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[CustomRichTextType]

export enum CustomRichTextTypeEnum {
  PlatformOverview = 1,
  Disclaimer = 2,
  Homepage = 3,
  Help = 4,
  LabelsAndDefinitionsList = 5,
  Training = 6,
  CustomPages = 7,
  EmployeeIndex = 8,
  EmployeeDetail = 9,
  EmployeeDetailEdit = 10,
  EmployeeQualifications = 11,
  QualificationIndex = 12,
  QualificationDetail = 13,
  ClientIndex = 14,
  ClientDetail = 15,
  ProjectIndex = 16,
  ProjectDetail = 17,
}


//test