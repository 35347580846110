import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceLineFormComponent } from '../shared/service-line-form/service-line-form.component';
import { CurrentServiceLineService } from 'src/app/services/current-service-line.service';
import { ServiceLineDto } from 'src/app/shared/generated/model/service-line-dto';
import { ServiceLineUpsertDto } from 'src/app/shared/generated/model/service-line-upsert-dto';

@Component({
  selector: 'talentbridge-service-line-create',
  templateUrl: './service-line-create.component.html',
  styleUrls: ['./service-line-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ServiceLineFormComponent
]
})
export class ServiceLineCreateComponent implements OnInit, OnDestroy {        
  @ViewChild("createNewServiceLineForm", { read: ServiceLineFormComponent }) form: ServiceLineFormComponent;

  public serviceLine: ServiceLineDto = new ServiceLineDto();
  public serviceLineUpsertDto: ServiceLineUpsertDto;
  private serviceLineResponse: any;

  editMode = true;

  constructor(
      private currentServiceLineService: CurrentServiceLineService,
      private router: Router,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.serviceLineUpsertDto = this.currentServiceLineService.createServiceLineDto(this.serviceLine);
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  save(result): void {
    if (!!result) {
        this.serviceLineResponse = result;
        this.router.navigateByUrl(`service-lines/${result.ServiceLineID}`);
    } else {
        this.cdr.detectChanges();
    }
  }

  cancelEdit() {
      this.router.navigateByUrl("service-lines");
  }

  canExit() {
      if (this.serviceLineResponse) {
          return true;
      } else {
          return JSON.stringify(this.serviceLineUpsertDto) === JSON.stringify(this.form.serviceLineUpsertDto);
      }
  }
}
