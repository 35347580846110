<div class="detail-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title">Summary</h1>

        @if (employee$ | async; as emp) {
            <div class="actions-bar">
                @if (canEdit(emp)) {
                    <esa-material-button
                        class="secondary-outline-button"
                        icon="edit"
                        label="Edit"
                        (click)="enableEditMode()"
                    >
                    </esa-material-button>
                }
            </div>
        }
    </div>

    <div class="copy copy-2">
        @if (editMode) {
            <custom-rich-text
                [customRichTextTypeID]="editRichTextTypeID"
                [userCanEdit]="canEditText"
                editorId="employeeDetailEditPageText">
            </custom-rich-text>
        }
        @else {
            <custom-rich-text
                [customRichTextTypeID]="detailRichTextTypeID"
                [userCanEdit]="canEditText"
                editorId="employeeDetailPageText">
            </custom-rich-text>
        }
    </div>

    @if (employee$ | async; as emp) {
        <talentbridge-employee-form
            #updateEmployeeForm
            [userID]="emp.UserID"
            [employee]="emp"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"
        >
        </talentbridge-employee-form>
    }
</div>
