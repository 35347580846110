<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">Help</h1>
    </div>

    <div class="copy copy-2 copy-55 pb-3">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEdit"
            editorId="helpPageText"
        ></custom-rich-text>
    </div>
</div>
