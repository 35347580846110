<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">Employee-Owners</h1>
    </div>
    <div class="table-responsive">
        <talentbridge-grid
            #clientEmployeeGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="employees"
            recordNamePlural="Employees"
            [pagination]="true"
            [sizeColumnsToFitGrid]="true"
            (gridReady)="onClientEmployeeGridReady($event)">
        </talentbridge-grid>
    </div>
</div>
