<div class="list-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title has-icon">
            <mat-icon fontSet="material-icons-outlined">category</mat-icon>
            Service Categories
        </h1>
        @if (canCreate) {
            <esa-material-button
                class="secondary-outline-button"
                label="Add Service Category"
                icon="add"
                type="primary"
                (click)="navigateToServiceCategoryCreatePage()"
            >
            </esa-material-button>
        }
    </div>
    <div class="table-responsive">
        <talentbridge-grid
            #serviceCategoriesGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="serviceCategories"
            recordNamePlural="Service Categories"
            (gridReady)="onServiceCategoriesGridReady($event)"
        >
        </talentbridge-grid>
    </div>
</div>
