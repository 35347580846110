<div class="list-view">
  <div class="page-header">
    <div class="actions-bar flex-between">
      <h1 class="page-title">Users</h1>
    </div>

    <div class="copy copy-2">
      <p>A list of users is shown in the grid below.</p>
    </div>
  </div>

  <div class="all-users">

    <div class="table-responsive">      
      <talentbridge-grid
        #usersGrid
        height="500px"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        downloadFileName="users"
        recordNamePlural="Users"
        [pagination]="true"
        [sizeColumnsToFitGrid]="true"
        (gridReady)="onUsersGridReady($event)">
      </talentbridge-grid>
    </div>
  </div>

  <hr class="section-spacer" />

  <div class="unassigned-users">
    <div class="section-header">
      <h2 class="section-title">Unassigned Users</h2>

      <div class="copy copy-2">
        @if (users && unassignedUsers.length) {
          <p>
            This is a list of all users who are awaiting account configuration. Assign the user a role. If the account was created in error, edit the record and save it with a role of No Acess to remove them from this list.
          </p>
        }

        @if (users && !unassignedUsers.length) {
          <p>
            Congratulations! All users have been assigned a role.
          </p>
        }
      </div>

      @if (users && unassignedUsers.length) {
        <div class="mt-4">
          <div class="table-responsive">
            
            <talentbridge-grid
              #unassignedUsersGrid
              height="300px"
              [rowData]="unassignedUsers"
              [columnDefs]="columnDefs"
              downloadFileName="unassignedUsers"
              recordNamePlural="Unassigned Users"
              [pagination]="true"
              [sizeColumnsToFitGrid]="true">
            </talentbridge-grid>
          </div>
        </div>
      }
    </div>
  </div>
</div>