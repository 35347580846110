import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentProjectService } from 'src/app/services/current-project.service';
import { ProjectDto } from 'src/app/shared/generated/model/project-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AgGridAngular } from 'ag-grid-angular';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProjectFileService } from 'src/app/shared/generated/api/project-file.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { ConfirmDialog } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FileService } from 'src/app/shared/services/file/file.service';
import { FileUploaderComponent } from '../../../../shared/components/file-uploader/file-uploader.component';
import { forkJoin } from 'rxjs';
import { NgClass, NgFor} from '@angular/common';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { Gallery, ImageItem } from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { GalleryImageDef } from 'ng-gallery';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'talentbridge-project-photos',
    templateUrl: './project-photos.component.html',
    styleUrls: ['./project-photos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AgGridAngular,
        FileUploaderComponent,
        NgFor,
        NgClass,
        EsaMaterialButtonComponent,
        GalleryImageDef,
        MatIconModule,
    ],
})
export class ProjectPhotosComponent implements OnInit, OnDestroy {
    @ViewChild(GalleryImageDef, { static: true }) galleryImageDef: GalleryImageDef;

    public project: ProjectDto;
    public currentUser: UserDto;
    public fileRoute: string;
    public projectPhotoFiles: any[];
    public isLoading: Boolean = false;
    public project$: Observable<any>;
    public hasUnuploadedFiles: Boolean = false;
    public selectedPhoto: any;

    currentUserSubscription: Subscription;
    projectPhotosSubscription: Subscription;

    constructor(
        private projectFileService: ProjectFileService,
        private fileService: FileService,
        private currentProjectService: CurrentProjectService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private gallery: Gallery,
        private lightbox: Lightbox,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;

            this.currentProjectService.currentProject$.subscribe((result) => {
                this.project = result;    
                this.refreshData();
            });
        });
    }
   
    refreshData() {
        if (this.project) {
            this.fileRoute = `projects/${this.project.ProjectID}/files`;
            this.isLoading = true;

            this.projectPhotosSubscription = this.projectFileService.projectsProjectIDFilesGet(this.project.ProjectID, true).subscribe((result) => {
                this.projectPhotoFiles = result;
    
                if (this.projectPhotoFiles.length < 1) {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                    return;
                }

                let photoObservables = [];
                this.projectPhotoFiles.forEach((file) => {
                    file.$UploadedBy = file?.UpdateUserName ?? file?.CreateUserName;
                    file.$UploadedOn = file?.UpdateDate ?? file?.CreateDate;
                    file.$ObjectSize = this.formatFileSize(file.ObjectSize);
                    photoObservables.push(this.fileService.downloadFile(`${this.fileRoute}/${file.BlobName}`));
                });

                forkJoin(photoObservables).subscribe((results) => {
                    let galleryPhotos = [];
                    this.projectPhotoFiles.forEach((photoFile, i) => {
                        let photo = results[i];

                        if (photo) {
                            const file = new File([photo], photoFile.Name);
                            
                            let galleryPhoto = URL.createObjectURL(file);;
                            if (navigator.msSaveBlob) {
                                photoFile.$Photo =  navigator.msSaveBlob(file, file.name);
                            } else {
                                photoFile.$Photo = galleryPhoto;
                            }

                            galleryPhotos.push(new ImageItem({
                                src: galleryPhoto,
                                thumb: galleryPhoto,
                                alt: photoFile.Name
                            }));
                        }
                    });

                    const galleryRef = this.gallery.ref(this.project.Name);
                    galleryRef.load(galleryPhotos);
                    galleryRef.state.subscribe((state) => {
                        if (state.action === "indexChanged") {
                            let photo = this.projectPhotoFiles[state.currIndex];
                            this.imageClicked(photo, false);
                        }
                    });
                    galleryRef.setConfig({
                        imageTemplate: this.galleryImageDef.templateRef
                    });

                    this.isLoading = false;
                    this.cdr.markForCheck();
                });
            });
        }
    }

    // Uploading and deleting ProjectFiles are considered updating the parent Project
    canUploadProjectFile(currentUser: UserDto) {
        return this.currentProjectService.canEditCurrentProject(this.currentUser, this.project);
    }

    canDeleteProjectFile(currentUser: UserDto) {
        return this.currentProjectService.canEditCurrentProject(this.currentUser, this.project);
    }

    hasFilesToUpload(event) {
        this.hasUnuploadedFiles = event;
    }

    fileUploadStarted() {
        this.isLoading = true;
        this.cdr.markForCheck();
    }

    fileUploadSuccess() {
        this.refreshData();
        this.alertService.pushAlert(new Alert("The file(s) were successfully uploaded.", AlertContext.Success))
    }
    
    downloadFile() {
        if (navigator.msSaveBlob) {
            return this.selectedPhoto.$Photo;
        }

        this.downloadAsHref(this.selectedPhoto.$Photo, this.selectedPhoto.Name);
    }

    downloadAsHref(href: string, filename: string) {
        const tempDownloadLink = document.createElement("a");
        tempDownloadLink.href = href;
        tempDownloadLink.download = filename;

        document.body.appendChild(tempDownloadLink);
        tempDownloadLink.click();
        document.body.removeChild(tempDownloadLink);
    }

    markAsKey() {
        this.projectFileService.projectsProjectIDFilesBlobNameKeyPhotoPut(this.project.ProjectID, this.selectedPhoto.BlobName).subscribe(() => {
            this.alertService.pushAlert(new Alert(`Successfully marked as key photo.`, AlertContext.Success, true));
            this.refreshData();
        }, error => {
            this.alertService.pushAlert(new Alert(`There was an error updating key photo. Please try again.`, AlertContext.Danger, true));
        });
    }

    deleteFile() {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Delete Project Photo",
                text: `You are about to delete the file ${this.selectedPhoto.Name}. This action cannot be undone. Are you sure you wish to proceed?`,
            }
        });
    
        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.projectFileService.projectsProjectIDFilesBlobNameDelete(this.project.ProjectID, this.selectedPhoto.BlobName).subscribe(() => {
                  this.alertService.pushAlert(new Alert(`Project Photo was successfully deleted.`, AlertContext.Success, true));
                  this.selectedPhoto = null;
                  this.refreshData();
                }, error => {
                  this.alertService.pushAlert(new Alert(`There was an error deleting the project photo. Please try again.`, AlertContext.Danger, true));
                });
            }
        });
    }

    formatFileSize(bytes, decimals = 2) {
        if (!+bytes) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    ngOnDestroy(): void {
        this.currentUserSubscription?.unsubscribe();
        this.projectPhotosSubscription?.unsubscribe();
    }

    canExit() {
        return !this.isLoading && !this.hasUnuploadedFiles;
    }

    imageClicked(photoFile, openGallery = true) {
        if (openGallery && photoFile == this.selectedPhoto) {
            this.viewPhotoGallery();
            return;
        }

        this.selectedPhoto = photoFile;

        this.cdr.markForCheck();
    }

    viewPhotoGallery() {
        let selectedIndex = 0;

        if (this.selectedPhoto != null) {
            selectedIndex = this.projectPhotoFiles.findIndex((photo) => {
                return photo.Name === this.selectedPhoto.Name;
            })
        }

        this.lightbox.open(selectedIndex, this.project.Name, {
            panelClass: "fullscreen"
        })
    }

    imageContextMenu(event) {
        return false;  // blocks download via context menu, since does not work
    }
}
