<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title has-icon">
                <mat-icon fontSet="material-icons-outlined">badge</mat-icon>
                Employee-Owners
            </h1>
        </div>
    </div>

    <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="employeeListPageText">
        </custom-rich-text>
    </div>

    <div class="filter-bar">
        <mat-icon>tune</mat-icon>
        <h3>Filters</h3>
        @if (filteredQualificationDtos.length == 0) {
            <esa-material-button
                title="Skills & Qualifications"
                label="Skills & Qualifications"
                icon="military_tech"
                type="secondary"
                (click)="filterQuals()">
            </esa-material-button>
        } 
        @else {
            <esa-material-button
                [title]="filteredQualificationDtos[0].Qualification.Name + (filteredQualificationDtos.length > 1 ? ' +' + (filteredQualificationDtos.length - 1) : '')"
                [label]="filteredQualificationDtos[0].Qualification.Name + (filteredQualificationDtos.length > 1 ? ' +' + (filteredQualificationDtos.length - 1) : '')"
                icon="military_tech"
                type="primary"
                (click)="filterQuals()">
            </esa-material-button>
        }

        @if(!searchLocation) {
            <esa-material-button
                title="Location"
                label="Location"
                icon="public"
                type="secondary"
                (click)="filterLocation()">
            </esa-material-button>
        }
        @else {
            <esa-material-button
                [title]="searchLocation.display_name + (searchDistance > 0 ? ' (' + searchDistance + ' miles)' : '')"
                [label]="searchLocation.display_name + (searchDistance > 0 ? ' (' + searchDistance + ' miles)' : '')"
                icon="public"
                type="primary"
                (click)="filterLocation()">
            </esa-material-button>
        }
    </div>
    <div>
        <div class="table-responsive">
            <!-- Pagination doesn't work well with External filters in this component. -->
            <talentbridge-grid
                #EmployeesGrid
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                downloadFileName="employees"
                recordNamePlural="Employees"
                [pagination]="false"
                [isExternalFilterPresent]="isExternalFilterPresent.bind(this)"
                [doesExternalFilterPass]="doesExternalFilterPass.bind(this)"
                (gridReady)="onEmployeesGridReady($event)"
                (filterCleared)="clearFilters()">
            </talentbridge-grid>
        </div>
    </div>
</div>
