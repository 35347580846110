<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">New Project</h1>
        <p>This page allows you to create a new project.</p>
    </div>

    <talentbridge-project-form
        #createNewProjectForm
        [project]="project"
        [editMode]="editMode"
        (formSubmitted)="save($event)"
        (cancelEditModeChange)="cancelEdit()"
    ></talentbridge-project-form>
</div>
