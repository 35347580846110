<div class="detail-view">
    <div class="page-header">
        <h3 class="page-title">Projects</h3>
    </div>

    <div class="table-responsive">
        <talentbridge-grid
            #employeeProjectGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="projects"
            recordNamePlural="Projects"
            [pagination]="true"
            [sizeColumnsToFitGrid]="true"
            (gridReady)="onEmployeeProjectGridReady($event)"
        >
        </talentbridge-grid>
    </div>
</div>
