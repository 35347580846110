<div class="site">
    @if (employee$ | async; as emp) {
        <div class="dashboard">
            <aside class="sidebar">
                <div class="sidebar-header">
                    @if (emp.ProfilePhotoBase64) {
                        <img
                            [src]="emp.ProfilePhotoBase64"
                            [alt]="emp.FullName"
                            class="profile-photo"
                        />
                    }

                    <h3 class="sidebar-header__title">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >badge</mat-icon
                        >
                        {{ emp.FullName }}
                    </h3>
                </div>

                <div class="sidebar-content">
                    <div class="sidebar-content__header">
                        <h4 class="sidebar-content__title">
                            Employee-Owner Profile
                        </h4>
                    </div>

                    <div class="sidebar-content__body">
                        <ul class="sidebar-nav">
                            @for (item of employeeSideNav; track item) {
                                @if (item.CanShow(emp)) {
                                    <li class="sidebar-item">
                                        <a
                                            [routerLink]="item.Link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            class="sidebar-link"
                                            >{{ item.Name }}</a
                                        >
                                    </li>
                                }
                            }
                        </ul>
                    </div>
                </div>

                <div class="sidebar-footer">
                    <div class="sidebar-footer__meta">
                        <p><strong>Name:</strong> {{ emp.FullName }}</p>
                        <p><strong>Job Title:</strong> {{ emp.JobTitle }}</p>
                        <p><strong>Department:</strong> {{ emp.Department }}</p>
                        <p>
                            <strong>Office Location:</strong>
                            {{ emp.OfficeLocation }}
                        </p>
                        <p>
                            <strong>Manager:</strong>
                            {{ emp.Manager?.FullName }}
                        </p>
                        <p>
                            <strong>Last Review:</strong>
                            {{ emp.LastReviewDate | date: 'shortDate' }}
                        </p>
                    </div>
                </div>
            </aside>

            <div class="main">
                <router-outlet #manageOutlet="outlet"></router-outlet>
            </div>
        </div>
    }
</div>
