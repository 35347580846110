<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">Photos</h1>
    </div>

    @if (projectPhotoFiles) {
        <div class="grid-12 thumbnail-container">
            @if (projectPhotoFiles.length > 0) {
                <div class="g-col-12">
                    <esa-material-button
                        label="View Photo Gallery"
                        title="View Photo Gallery"
                        type="primary"
                        icon="photo_camera"
                        (click)="viewPhotoGallery()"
                    ></esa-material-button>
                    @if (selectedPhoto) {
                        <esa-material-button
                            label="Download"
                            title="Download Selected Photo"
                            type="clear"
                            icon="download"
                            (click)="downloadFile()"
                        ></esa-material-button>
                        <esa-material-button
                            label="Key Photo"
                            title="Mark as Key Project Photo"
                            type="clear"
                            icon="key"
                            (click)="markAsKey()"
                        ></esa-material-button>
                        <esa-material-button
                            label="Delete"
                            title="Delete Selected Photo"
                            type="clear"
                            icon="delete"
                            (click)="deleteFile()"
                        ></esa-material-button>
                    }
                </div>
            }
        </div>
    
        @if (projectPhotoFiles.length > 0) {
            <div class="g-col-12">
            <esa-material-button label="View Photo Gallery" title="View Photo Gallery" type="primary" icon="photo_camera" (click)="viewPhotoGallery()"></esa-material-button>
            @if (selectedPhoto) {
                <esa-material-button label="Download" title="Download Selected Photo" type="clear" icon="download" (click)="downloadFile()"></esa-material-button>
                @if (canUploadProjectFile(currentUser)) {
                <esa-material-button label="Key Photo" title="Mark as Key Project Photo" type="clear" icon="key" (click)="markAsKey()"></esa-material-button>
                <esa-material-button label="Delete" title="Delete Selected Photo" type="clear" icon="delete" (click)="deleteFile()"></esa-material-button>
                }
            }
                <div
                    *ngFor="let photoFile of projectPhotoFiles"
                    class="g-col-2"
                    style="justify-self: center"
                    (contextmenu)="imageContextMenu($event)"
                >
                    @if (photoFile.IsKeyFile) {
                        <mat-icon  style="position: absolute; color: #f08b1d; background-color: white;" fontSet="material-icons-outlined">key</mat-icon>
                    }
                    <img
                        class="thumbnail"
                        [src]="photoFile.$Photo"
                        [title]="photoFile.Name"
                        [alt]="photoFile.Name"
                        [ngClass]="{
                            'selected-thumbnail': photoFile == selectedPhoto
                        }"
                        (click)="imageClicked(photoFile)"
                    />
                </div>

                @if (projectPhotoFiles.length < 1) {
                    <div class="g-col-12">No photos to display.</div>
                }
            </div>
        }
    }
    @if (fileRoute && canUploadProjectFile(currentUser)) {
        <div style="margin-left: 32px">
            <talentbridge-file-uploader
                [fileRoute]="fileRoute"
                (hasFilesToUpload)="hasFilesToUpload($event)"
                (fileUploadStarted)="fileUploadStarted()"
                (fileUploadSuccess)="fileUploadSuccess()"
                allowedFileExtensions=".bmp,.jpeg,.jpg,.png,.tiff"
            >
            </talentbridge-file-uploader>
        </div>
    }
</div>

<div *galleryImageDef="let item" class="custom-gallery-caption">
    {{ item.alt }}
</div>
