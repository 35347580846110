import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentServiceCategoryService } from 'src/app/services/current-service-category.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { ServiceCategoryFormComponent } from '../../shared/service-category-form/service-category-form.component';
import { ServiceCategoryDto } from 'src/app/shared/generated/model/service-category-dto';
import { ServiceCategoryUpsertDto } from 'src/app/shared/generated/model/service-category-upsert-dto';
import { EsaMaterialButtonComponent } from 'esa-material-form-field';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'talentbridge-service-category-detail-summary',
    templateUrl: './service-category-detail-summary.component.html',
    styleUrls: ['./service-category-detail-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    ServiceCategoryFormComponent,
    EsaMaterialButtonComponent,
    AsyncPipe
]
})
export class ServiceCategoryDetailSummaryComponent implements OnInit, OnDestroy {
  @ViewChild("updateServiceCategoryForm", { read: ServiceCategoryFormComponent }) form: ServiceCategoryFormComponent;

    public serviceCategory$: Observable<any>;
    public currentUser: UserDto;
    public editMode = false;
    public originalServiceCategoryUpsertDto: ServiceCategoryUpsertDto;

    currentUserSubscription: Subscription;

    constructor(
        private currentServiceCategoryService: CurrentServiceCategoryService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.serviceCategory$ = this.currentServiceCategoryService.getCurrentServiceCategory().pipe(
            map((data) => {
                this.originalServiceCategoryUpsertDto = this.currentServiceCategoryService.createServiceCategoryDto(data);
                this.cdr.markForCheck();
                return data;
            })
        );
    }

    ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
    }

    refreshServiceCategory(result: any) {
        this.editMode = false;
        this.currentServiceCategoryService.setCurrentServiceCategory(result);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save(result) {
        this.refreshServiceCategory(result);
    }

    canEdit(serviceCategory: ServiceCategoryDto): boolean {
        return this.currentServiceCategoryService.canEditCurrentServiceCategory(this?.currentUser, serviceCategory) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.originalServiceCategoryUpsertDto) === JSON.stringify(this.form.serviceCategoryUpsertDto);
        } else {
            return true;
        }
    }
}
