<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">Projects</h1>
    </div>
    <div class="table-responsive">
        <talentbridge-grid
            #clientProjectGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="projects"
            recordNamePlural="Projects"
            [pagination]="true"
            [sizeColumnsToFitGrid]="true"
            (gridReady)="onClientProjectGridReady($event)">
        </talentbridge-grid>
    </div>
</div>
