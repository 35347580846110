import {  ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, EventEmitter, Input, model, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrentProjectService } from 'src/app/services/current-project.service';
import { ProjectService } from 'src/app/shared/generated/api/project.service';
import { ProjectUpsertDto } from 'src/app/shared/generated/model/project-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BypassHtmlPipe } from '../../../../pipes/bypass-html.pipe';
import { TinymceEditorComponent } from '../../../../shared/components/tinymce-editor/tinymce-editor.component';
import { NgClass } from '@angular/common';
import { CustomFormLabelComponent } from '../../../../shared/components/custom-form-label/custom-form-label.component';
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent, EsaLabelComponent } from 'esa-material-form-field';
import { ProjectDto } from 'src/app/shared/generated/model/project-dto';
import { RouterLink } from '@angular/router';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ClientSummaryDto } from 'src/app/shared/generated/model/client-summary-dto';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { ClientService } from 'src/app/shared/generated/api/client.service';

@Component({
  selector: 'talentbridge-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    EsaMaterialFormFieldComponent,
    CustomFormLabelComponent,
    NgClass,
    TinymceEditorComponent,
    EsaMaterialButtonComponent,
    BypassHtmlPipe,
    RouterLink,
    EsaLabelComponent,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatLabel,
    MatSelect,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFormComponent implements OnInit {
  @ViewChild("projectForm", { read: NgForm }) form: NgForm;
  @ViewChild("descriptionEditor") descriptionEditor: TinymceEditorComponent;
  @ViewChild("standardEditor") standardEditor: TinymceEditorComponent;
  @ViewChild("opportunityEditor") opportunityEditor: TinymceEditorComponent;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() projectID: number;
  @Input() project: ProjectDto;
  @Input() editMode: boolean;

  clients: ClientSummaryDto[];
  projectUpsertDto: ProjectUpsertDto;

  // Signals used for autoComplete
  readonly currentClient = model('');
  readonly filteredClients = computed(() => {
    const currentClient = this.currentClient();
    return currentClient
      ? this.clients.filter(c => c.Name.toLowerCase().includes(currentClient))
      : this.clients.slice();
  });


  constructor(private currentProjectService: CurrentProjectService,
    private clientService: ClientService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.project) {
      this.clientService.clientsGet().subscribe((clients) => {
        this.clients = clients;
        this.setForm();
      });
    }
  }

  setForm() {
    this.projectUpsertDto = this.currentProjectService.createProjectDto(this.project);
    this.currentClient.set(this.project.Client ? this.project.Client.Name : '');  
    this.cdr.detectChanges();
  }

  saveForm(form: NgForm) {
    if (!!this.projectID) {
      this.projectService.projectsProjectIDPut(this.projectID, this.projectUpsertDto)
        .subscribe((result) => {
          this.alertService.pushAlert(new Alert("The project was successfully updated.", AlertContext.Success), 5000);
          this.formSubmitted.emit(result);
        });
    } else {
      this.projectService.projectsPost(this.projectUpsertDto)
        .subscribe((result) => {
          this.formSubmitted.emit(result);
          this.alertService.pushAlert(new Alert("The project was successfully created.", AlertContext.Success), 5000);
        },
          () => {
            this.formSubmitted.emit(null);
          });
    }
  }

  cancelEditMode() {
    this.setForm();
    this.cancelEditModeChange.emit(true);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    setTimeout(() => { // this is a hack to make sure the value is set after the closed event is fired.
      this.projectUpsertDto.ClientID = event.option.value.ClientID;
   });
  }

  //This is to ensure that when a value isn't selected the ClientID is updated to null
  closed() {
    this.projectUpsertDto.ClientID = null;
  }

  clearAutoComplete() {
    this.currentClient.set(''); 
    this.projectUpsertDto.ClientID = null;
  }

  getName(client: ClientSummaryDto | string) {
    if (typeof client === 'string') {
      return client;
    }
    return client?.Name;
  }

  getTooltip() {
    if (this.projectUpsertDto.Name?.length > 255) {
      return "Name field must be 255 characters or less."
    }
    
    return 'You are missing required fields.';
  }
}
