<div class="detail-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title">Summary</h1>
        <div class="actions-bar">
            @if (canEdit()) {
                <esa-material-button
                    class="secondary-outline-button"
                    icon="edit"
                    label="Edit"
                    (click)="enableEditMode()"
                >
                </esa-material-button>
            }
        </div>
    </div>

    <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="projectDetailPageText">
        </custom-rich-text>
    </div>

    @if (project$ | async; as p) {
        <div class="grid-12">
            <div class="g-col-4">
                <div class="map-container">
                    <map (onMapLoad)="mapInit($event)">
                        @if (map) {
                            @if (p.Latitude && p.Longitude) {
                                <map-marker
                                    [latitude]="p.Latitude"
                                    [longitude]="p.Longitude"
                                    [label]="p.Name"
                                    [map]="map"
                                    [zoomTo]="true"
                                ></map-marker>
                            } @else {
                                <div class="map-no-location">
                                    <p>
                                        <mat-icon>location_off</mat-icon>No location
                                        data available.
                                    </p>
                                </div>
                            }
                        }
                    </map>
                    <div class="grid-12 map-location">
                        <div class="g-col-12">
                            <esa-material-ff
                                name="Location"
                                [editMode]="false"
                                label="Location"
                                type="text"
                                [(ngModel)]="projectUpsertDto.Location">
                            </esa-material-ff>
                        </div>

                        <div class="g-col-6">
                            <esa-material-ff
                                name="Latitude"
                                [editMode]="false"
                                type="text"
                                [(ngModel)]="projectUpsertDto.Latitude"
                                [customLabel]="LatitudeCustomLabel">
                            </esa-material-ff>
                            <ng-template #LatitudeCustomLabel>
                                <talentbridge-custom-form-label label="Latitude"></talentbridge-custom-form-label>
                            </ng-template>
                        </div>
                      
                        <div class="g-col-6">
                            <esa-material-ff
                                name="Longitude"
                                [editMode]="false"
                                type="text"
                                [(ngModel)]="projectUpsertDto.Longitude"
                                [customLabel]="LongitudeCustomLabel">
                            </esa-material-ff>
                            <ng-template #LongitudeCustomLabel>
                                <talentbridge-custom-form-label label="Longitude"></talentbridge-custom-form-label>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="g-col-8">
                <talentbridge-project-form
                    #updateProjectForm
                    [projectID]="p.ProjectID"
                    [project]="p"
                    [editMode]="editMode"
                    (formSubmitted)="save($event)"
                    (cancelEditModeChange)="cancelEdit()"
                ></talentbridge-project-form>
            </div>
        </div>
    }
</div>
