import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from "@angular/core";
import { ColDef } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { QualificationService } from "src/app/shared/generated/api/qualification.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { MatIcon } from "@angular/material/icon";


@Component({
    selector: "talentbridge-qualification-list",
    templateUrl: "./qualification-list.component.html",
    styleUrls: ["./qualification-list.component.scss"],
    standalone: true,
    imports: [
        CustomRichTextComponent,
        EsaMaterialButtonComponent,
        TalentBridgeGridComponent,
        MatIcon
    ]
})
export class QualificationListComponent implements OnInit, OnDestroy {

    private currentUser: UserDto;

    canEditText: boolean;  
    public richTextTypeID : number = CustomRichTextTypeEnum.QualificationIndex;
    public canCreate: boolean;

    public rowData = [];
    columnDefs: ColDef[];

    userSubscription: Subscription;
    qualificationSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private qualificationService: QualificationService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;

                this.canEditText = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);

                this.canCreate = this.authenticationService.hasPermission(
                    this.currentUser,
                    PermissionEnum.QualificationRights,
                    RightsEnum.Create
                );

                this.cdr.markForCheck();

                this.createColumnDefs();
            });
    }

    createColumnDefs() {
        this.columnDefs = [
            {
                headerName: "Service Line",
                field: "Service.ServiceCategory.ServiceLine.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.ServiceCategory.ServiceLine.Name",
                },
                tooltipField: "Service.ServiceCategory.ServiceLine.Name",
            },
            {
                headerName: "Service Category",
                field: "Service.ServiceCategory.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.ServiceCategory.Name",
                },
                tooltipField: "Service.ServiceCategory.Name",
            },
            {
                headerName: "Service",
                field: "Service.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.Name",
                },
                tooltipField: "Service.Name",
            },
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.QualificationID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: (params) => {
                    return {
                            component: LinkRendererComponent,
                            params: { inRouterLink: "./" },
                        };
                },
                filterValueGetter: function (params: any) {
                    return params.data.Name;
                },
                comparator: function (linkA: any, linkB: any) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 3,
                tooltipField: "$NameAndDescription",
                wrapText: true,
            },
            {
                headerName: "Employee Count",
                valueGetter: function (params: any) {
                    return params.data.EmployeeCount;
                },
                filter: "agNumberColumnFilter",
                flex: 2,
                tooltipField: "EmployeeCount",
                cellDataType: "number" 
            },
            {
                headerName: "Type",
                field: "QualificationType.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "QualificationType.Name",
                },
                tooltipField: "QualificationType.Name",
            },
        ];
    }

    onQualificationsGridReady(gridEvent) {
        gridEvent.api.showLoadingOverlay();
        this.qualificationSubscription = this.qualificationService
            .qualificationsGet()
            .subscribe((qualifications) => {
                this.rowData = qualifications.map((qual) => {
                    qual["$NameAndDescription"] = qual.Description ? `${qual.Name}: ${qual.Description}` : qual.Name;
                    return qual;
                });
                gridEvent.api.hideOverlay();
                this.cdr.markForCheck();
            });
    }

    navigateToQualificationCreatePage() {
        this.router.navigateByUrl("qualifications/create");
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.qualificationSubscription?.unsubscribe();
    }
}
