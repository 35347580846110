<div class="site">
    @if (client$ | async; as cli) {
        <div class="dashboard">
            <aside class="sidebar">
                <div class="sidebar-header">
                    <h4 class="sidebar-header__subtitle">Clients</h4>
                    <h3 class="sidebar-header__title">
                        <mat-icon
                            style="vertical-align: bottom"
                            fontSet="material-icons-outlined"
                            >account_balance</mat-icon
                        >
                        {{ cli.Name }}
                    </h3>
                </div>

                <div class="sidebar-content">
                    <div class="sidebar-content__header">
                        <h4 class="sidebar-content__title">Client Info</h4>
                    </div>
                    <ul class="sidebar-nav">
                        @for (item of clientSideNav; track item) {
                            <li class="sidebar-item">
                                <a
                                    [routerLink]="item.Link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="sidebar-link"
                                    >{{ item.Name }}</a
                                >
                            </li>
                        }
                    </ul>
                </div>
            </aside>
            <div class="main">
                <router-outlet #manageOutlet="outlet"></router-outlet>
            </div>
        </div>
    }
</div>
