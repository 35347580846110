<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title has-icon">
                <mat-icon fontSet="material-icons-outlined">account_balance</mat-icon>
                Clients
            </h1>
        </div>
    </div>

    <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="clientListPageText">
        </custom-rich-text>
    </div>
    <div class="table-responsive">
        <talentbridge-grid
            #ClientsGrid
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            downloadFileName="clients"
            recordNamePlural="Clients"
            [pagination]="true"
            [sizeColumnsToFitGrid]="true"
            (gridReady)="onClientsGridReady($event)">
        </talentbridge-grid>
    </div>
</div>
