import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CurrentServiceCategoryService } from 'src/app/services/current-service-category.service';
import { ServiceCategoryService } from 'src/app/shared/generated/api/service-category.service';
import { ServiceCategoryDto } from 'src/app/shared/generated/model/service-category-dto';
import { ServiceCategoryUpsertDto } from 'src/app/shared/generated/model/service-category-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { forkJoin } from 'rxjs';
import { ServiceLineService } from 'src/app/shared/generated/api/service-line.service';

@Component({
  selector: 'talentbridge-service-category-form',
  templateUrl: './service-category-form.component.html',
  styleUrls: ['./service-category-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EsaMaterialButtonComponent,
    EsaMaterialFormFieldComponent,
    FormsModule
  ]
})
export class ServiceCategoryFormComponent implements OnInit {
  @ViewChild("serviceCategoryForm", { read: NgForm }) form: NgForm;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() serviceCategoryID: number;
  @Input() serviceCategory: ServiceCategoryDto;
  @Input() editMode: boolean;

  serviceCategoryUpsertDto: ServiceCategoryUpsertDto;

  public serviceLines: any;

  constructor(private currentServiceCategoryService: CurrentServiceCategoryService,
    private serviceCategoryService: ServiceCategoryService,
    private serviceLineService: ServiceLineService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
      this.serviceLineService.serviceLinesGet()
      .subscribe((serviceLines) => {  
        this.serviceLines = serviceLines; 
        this.setForm();
      });
  }

  setForm() {
    this.serviceCategoryUpsertDto = this.currentServiceCategoryService.createServiceCategoryDto(this.serviceCategory);
    this.cdr.detectChanges();
  }

  saveForm(form: NgForm) {
    if(this.serviceCategoryID) {
      this.serviceCategoryService.serviceCategoriesServiceCategoryIDPut(this.serviceCategoryID, this.serviceCategoryUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The service category was successfully updated.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
    else {
      this.serviceCategoryService.serviceCategoriesPost(this.serviceCategoryUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The service category was successfully created.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }
  
  getTooltip() {
    if (this.serviceCategoryUpsertDto.Name?.length > 100) {
      return "Name field must be 100 characters or less."
    }
    
    return 'You are missing required fields.';
  }
}