import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    ActivatedRoute,
    Router,
    RouterLinkActive,
    RouterLink,
    RouterOutlet,
} from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentProjectService } from "src/app/services/current-project.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { ConfirmDialog } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { AsyncPipe } from "@angular/common";

@Component({
    selector: "talentbridge-project-detail",
    templateUrl: "./project-detail.component.html",
    styleUrls: ["./project-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatTooltip,
        MatButton,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        RouterLinkActive,
        RouterLink,
        RouterOutlet,
        AsyncPipe,
    ],
})
export class ProjectDetailComponent implements OnInit {
    public project$: Observable<any>;
    public project?: ProjectDto | undefined;

    public currentUser: UserDto;
    public editMode: boolean = false;
    public projectSideNav: any[] = [];

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;

    constructor(
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        const projectID = parseInt(this.route.snapshot.paramMap.get("id"));
        this.projectSideNav = [
            {
                Name: "Summary",
                Link: `/projects/${projectID}`,
            },
            {
                Name: "Employee-Owners",
                Link: `/projects/${projectID}/employee-list`,
            },
            {
                Name: "Photos",
                Link: `/projects/${projectID}/photos`,
            },
            {
                Name: "File List",
                Link: `/projects/${projectID}/file-list`,
            },
        ];

        this.projectService.projectsProjectIDGet(projectID).subscribe(
            (data) => {
                this.currentProjectService.setCurrentProject(data);
            },
            () => {
                this.router.navigate([""]);
            },
        );

        this.project$ = this.currentProjectService.getCurrentProject().pipe(
            map((data) => {
                this.project = data;
                return data;
            }),
        );

        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.cdr.markForCheck();
            });

        this.editViewButtonClicked = this.editViewEventService
            .listenForEditButtonClicked()
            .subscribe((result) => {
                this.editMode = result;
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.currentProjectService.setCurrentProject(null);
        this.user.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    refreshProject(result: any) {
        this.project = result;
        this.currentProjectService.setCurrentProject(this.project);
        this.cdr.detectChanges();
    }

    canDelete() {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.ProjectRights,
            RightsEnum.Delete,
        );
    }

    public delete() {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Delete Project",
                text: `You are about to delete project ${this.project.Name}. This action cannot be undone. Are you sure you wish to proceed?`,
            },
        });

        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.projectService
                    .projectsProjectIDDelete(this.project.ProjectID)
                    .subscribe(
                        () => {
                            this.alertService.pushAlert(
                                new Alert(
                                    `Project was successfully deleted.`,
                                    AlertContext.Success,
                                    true,
                                ),
                            );
                            this.router.navigate(["/projects"]);
                        },
                        (error) => {
                            this.alertService.pushAlert(
                                new Alert(
                                    `There was an error deleting the project. Please try again.`,
                                    AlertContext.Danger,
                                    true,
                                ),
                            );
                        },
                    );
            }
        });
    }

    hasReadOnly() {
        const read = this.authenticationService.hasPermission(
            this?.currentUser,
            PermissionEnum.ProjectRights,
            RightsEnum.Read,
        );
        const update = this.authenticationService.hasPermission(
            this?.currentUser,
            PermissionEnum.ProjectRights,
            RightsEnum.Update,
        );
        const remove = this.authenticationService.hasPermission(
            this?.currentUser,
            PermissionEnum.ProjectRights,
            RightsEnum.Delete,
        );
        const create = this.authenticationService.hasPermission(
            this?.currentUser,
            PermissionEnum.ProjectRights,
            RightsEnum.Create,
        );
        if (read && !update && !remove && !create) {
            return true;
        } else {
            return false;
        }
    }
}
