import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceFormComponent } from '../shared/service-form/service-form.component';
import { CurrentServiceService } from 'src/app/services/current-service.service';
import { ServiceDto } from 'src/app/shared/generated/model/service-dto';
import { ServiceUpsertDto } from 'src/app/shared/generated/model/service-upsert-dto';


@Component({
  selector: 'talentbridge-service-create',
  templateUrl: './service-create.component.html',
  styleUrls: ['./service-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ServiceFormComponent
]
})
export class ServiceCreateComponent implements OnInit, OnDestroy {        
  @ViewChild("createNewServiceForm", { read: ServiceFormComponent }) form: ServiceFormComponent;

  public service: ServiceDto = new ServiceDto();
  public serviceUpsertDto: ServiceUpsertDto;
  private serviceResponse: any;

  editMode = true;

  constructor(
      private currentServiceService: CurrentServiceService,
      private router: Router,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.serviceUpsertDto = this.currentServiceService.createServiceDto(this.service);
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  save(result): void {
    if (!!result) {
        this.serviceResponse = result;
        this.router.navigateByUrl(`services/${result.ServiceID}`);
    } else {
        this.cdr.detectChanges();
    }
  }

  cancelEdit() {
      this.router.navigateByUrl("services");
  }

  canExit() {
      if (this.serviceResponse) {
          return true;
      } else {
          return JSON.stringify(this.serviceUpsertDto) === JSON.stringify(this.form.serviceUpsertDto);
      }
  }
}
