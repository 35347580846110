import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GeoLocationService {

    constructor(private httpClient: HttpClient) {}

    public searchLocation(search: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 
                'Access-Control-Allow-Origin':'*'
            })
        };
        return this.httpClient.get(`https://nominatim.openstreetmap.org/search?q=${search}&format=json`, httpOptions);

    }
}
