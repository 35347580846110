<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">File List</h1>
    </div>
  <div class="table-responsive">
    <talentbridge-grid        
        #projectFileGrid        
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        downloadFileName="files"
        recordNamePlural="Files"
        (gridReady)="onProjectFileGridReady($event)">
    </talentbridge-grid>
  </div>

    @if (fileRoute && canUploadProjectFile(currentUser)) {
        <talentbridge-file-uploader
            [fileRoute]="fileRoute"
            (hasFilesToUpload)="hasFilesToUpload($event)"
            (fileUploadStarted)="fileUploadStarted()"
            (fileUploadSuccess)="fileUploadSuccess()"
        >
        </talentbridge-file-uploader>
    }
</div>
