@if (currentUser.UserID === employee?.UserID) {
    <div class="employee-nav">
        <div
            matTooltip="Save or cancel edits to access options."
            [matTooltipDisabled]="!editMode"
        >
            <button
                mat-raised-button
                #profileMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="profile"
                class="primary-button"
                [disabled]="editMode"
            >
                <mat-icon>expand_more</mat-icon>&nbsp;Options
            </button>
            <mat-menu #profile="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="verifyQualifications()">
                    <mat-icon>check</mat-icon>
                    <span>Verify Qualifications</span>
                </button>
            </mat-menu>
        </div>
    </div>
}

<div class="detail-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title">Skills & Qualifications</h1>

        @if (employeeQualificationDtos && employee) {
            <div class="actions-bar">
                @if (canEdit(employee)) {
                    <esa-material-button
                        class="secondary-outline-button"
                        icon="edit"
                        label="Edit"
                        (click)="enableEditMode()"
                    >
                    </esa-material-button>
                }
                @if (editMode) {
                    <esa-material-button
                        style="margin-right: 1rem"
                        type="primary"
                        label="Save"
                        icon="save"
                        (click)="save()"
                    >
                    </esa-material-button>

                    <esa-material-button
                        type="clear"
                        label="Cancel"
                        icon="cancel"
                        (click)="cancelEditMode()"
                    >
                    </esa-material-button>
                }
            </div>
        }
    </div>

    <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="employeeQualificationPageText">
        </custom-rich-text>
    </div>

    @if (employeeQualificationDtos && employee && qualifications) {
        <talentbridge-employee-qualification-autocomplete
            [editMode]="editMode"
            [qualifications]="qualifications"
            [experienceLevels]="experienceLevels"
            [employeeQualificationDtos]="employeeQualificationDtos"
            (qualificationAdded)="addQualification($event)"
            (qualificationRemoved)="removeQualification($event)"
        >
        </talentbridge-employee-qualification-autocomplete>

        @if (editMode) {
            <div
                class="g-col-12 actions-bar actions-bar__footer"
                style="margin-top: 1rem"
            >
                <esa-material-button
                    style="margin-right: 1rem"
                    type="primary"
                    label="Save"
                    icon="save"
                    (click)="save()"
                >
                </esa-material-button>

                <esa-material-button
                    type="clear"
                    label="Cancel"
                    icon="cancel"
                    (click)="cancelEditMode()"
                >
                </esa-material-button>
            </div>
        }
    }
</div>
