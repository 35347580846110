import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CurrentServiceLineService } from 'src/app/services/current-service-line.service';
import { ServiceLineService } from 'src/app/shared/generated/api/service-line.service';
import { ServiceLineDto } from 'src/app/shared/generated/model/service-line-dto';
import { ServiceLineUpsertDto } from 'src/app/shared/generated/model/service-line-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';

@Component({
  selector: 'talentbridge-service-line-form',
  templateUrl: './service-line-form.component.html',
  styleUrls: ['./service-line-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EsaMaterialButtonComponent,
    EsaMaterialFormFieldComponent,
    FormsModule
  ]
})
export class ServiceLineFormComponent implements OnInit {
  @ViewChild("serviceLineForm", { read: NgForm }) form: NgForm;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() serviceLineID: number;
  @Input() serviceLine: ServiceLineDto;
  @Input() editMode: boolean;

  serviceLineUpsertDto: ServiceLineUpsertDto;

  constructor(private currentServiceLineService: CurrentServiceLineService,
    private serviceLineService: ServiceLineService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.serviceLineUpsertDto = this.currentServiceLineService.createServiceLineDto(this.serviceLine);

    this.cdr.detectChanges();
  }

  saveForm(form: NgForm) {
    if(this.serviceLineID) {
      this.serviceLineService.serviceLinesServiceLineIDPut(this.serviceLineID, this.serviceLineUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The service line was successfully updated.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
    else {
      this.serviceLineService.serviceLinesPost(this.serviceLineUpsertDto).subscribe((result) => {
        this.alertService.pushAlert(new Alert("The service line was successfully created.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
    }
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }

  getTooltip() {
    if (this.serviceLineUpsertDto.Name?.length > 100) {
      return "Name field must be 100 characters or less."
    }
    
    return 'You are missing required fields.';
  }
}