<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">Summary</h1>
    </div>
    <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="clientDetailPageText">
        </custom-rich-text>
    </div>

    @if (client$ | async; as cli) {
        <div class="actions-bar"></div>
        <talentbridge-client-form
            #updateClientForm
            [clientID]="cli.ClientID"
            [client]="cli"
        >
        </talentbridge-client-form>
    }
</div>
