import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { ColDef, GridApi } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererWithImageComponent } from "src/app/shared/components/ag-grid/link-renderer-with-image/link-renderer-with-image.component";
import { QualificationChipsRendererComponent } from "src/app/shared/components/ag-grid/qualification-chips-renderer/qualification-chips-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { EmployeeService } from "src/app/shared/generated/api/employee.service";
import { EmployeeQualificationSummaryDto } from "src/app/shared/generated/model/employee-qualification-summary-dto";
import { UserClaimsDto } from "src/app/shared/generated/model/user-claims-dto";
import { environment } from "src/environments/environment";
import { TalentBridgeGridComponent } from "../../../shared/components/talentbridge-grid/talentbridge-grid.component";
import { MatIconModule } from "@angular/material/icon";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { MatDialog } from "@angular/material/dialog";
import { QualificationFilterDialog } from "../shared/qualification-filter-dialog/qualification-filter-dialog.component";
import { LocationFilterDialog } from "../shared/location-filter-dialog/location-filter-dialog.component";

@Component({
    selector: "talentbridge-employee-list",
    templateUrl: "./employee-list.component.html",
    styleUrls: ["./employee-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CustomRichTextComponent, 
        TalentBridgeGridComponent,
        MatIconModule,
        EsaMaterialButtonComponent,
    ],
})
export class EmployeeListComponent implements OnInit {
    public currentUser: UserClaimsDto;
    canEditText: boolean;  
    public richTextTypeID : number = CustomRichTextTypeEnum.EmployeeIndex;
    public rowData = [];
    public columnDefs: ColDef[];
    user: Subscription;
    getEmployeeRequest: Subscription;
    gridApi: GridApi;
    
    filteredQualificationDtos: EmployeeQualificationSummaryDto[] = [];
    searchLocation: any;
    searchDistance: number = -1;

    constructor(
        private employeeService: EmployeeService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;

                this.canEditText = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);

                this.columnDefs = [
                    {
                        headerName: "Employee Name",
                        headerTooltip: "Employee Name",
                        valueGetter: function (params: any) {
                            return {
                                    LinkValue: params.data.UserID,
                                    LinkDisplay: params.data.FullName,
                                    ImageSrc: `${environment.mainAppApiUrl}/users/${params.data.UserID}/photoThumbnail?uid=${result.UserGuid}&secure=${result.FileAccessToken}`
                                };
                        },
                        cellRendererSelector: (params) => {
                            return {
                                    component: LinkRendererWithImageComponent,
                                    params: { inRouterLink: "/employees/" },
                                };
                        },
                        filterValueGetter: function (params: any) {
                            return params.data.FullName;
                        },
                        comparator: function (
                            linkA,
                            linkB,
                            nodeA,
                            nodeB,
                            isDescending
                        ) {
                            let valueA = linkA.LinkDisplay.toLowerCase();
                            let valueB = linkB.LinkDisplay.toLowerCase();
    
                            return valueA.localeCompare(valueB, undefined, {
                                numeric: true,
                                sensitivity: "base",
                            });
                        },
                        flex: 2,
                        sort: "asc",
                        tooltipField: "FullName",
                    },
                    {
                        headerName: "Job Title",
                        field: "JobTitle",
                        flex: 2,
                        tooltipField: "JobTitle",
                        wrapText: true,
                    },
                    {
                        headerName: "Department",
                        field: "Department",
                        flex: 2,
                        tooltipField: "Department",
                        wrapText: true,
                    },
                    {
                        headerName: "Office Location",
                        field: "OfficeLocation",
                        flex: 1,
                        filter: CustomDropdownFilterComponent,
                        filterParams: {
                            field: "OfficeLocation",
                        },
                        tooltipField: "OfficeLocation",
                        wrapText: true,
                    }, 
                    {
                        headerName: "ZipCode",
                        field: "ZipCode",
                        flex: 2,
                        tooltipField: "ZipCode"
                    }, 
                    {
                        headerName: "Distance",
                        field: "$HaversineDistance",
                        valueFormatter: p => p.value == undefined ? "" : p.value.toFixed(2) + " miles",
                        flex: 2,
                        wrapText: true,
                    },
                    {
                        headerName: "Qualifications Count",
                        field: "Qualifications.length",
                        flex: 2,
                        tooltipField: "Qualifications.length",
                        wrapText: true,
                    },
                    {
                        headerName: "Qualifications",
                        headerTooltip: "Qualifications",
                        field: "$FilteredQuals",
                        cellRenderer: QualificationChipsRendererComponent,
                        cellRendererParams: function (params: any) {
                            return {
                                Qualifications: params.data.$FilteredQuals
                            };
                        },
                        valueGetter: function (params: any) {
                            if(params.data.$FilteredQuals != null){
                                return {
                                    DownloadDisplay: params.data.$FilteredQuals?.map(x => x.Qualification.Name).join(", "),
                                };
                            }
                            else{
                                return {
                                    DownloadDisplay: "",
                                };
                            }
                        },
                        filter: null,
                        flex: 4,
                        autoHeight: true,
                    },          
                ];
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.user?.unsubscribe();
        this.getEmployeeRequest?.unsubscribe();
    }

    onEmployeesGridReady(gridEvent) {
        this.gridApi = gridEvent.api;
        this.gridApi.showLoadingOverlay();

        this.gridApi.setGridOption("isExternalFilterPresent", this.isExternalFilterPresent.bind(this));
        this.gridApi.setGridOption("doesExternalFilterPass", this.doesExternalFilterPass.bind(this));
        
        this.getEmployeeRequest = this.employeeService
            .employeesGet()
            .subscribe((results) => {
                this.rowData = results.filter((employee) => {
                    return employee.IsActive;
                }).map((employee) => { 
                    return {
                        ...employee,
                        $FilteredQuals: employee.Qualifications
                    };
                });
                
                this.gridApi.hideOverlay();
                this.updateColumnVisiblity();
                this.cdr.markForCheck();
            });
    }

    isExternalFilterPresent(): boolean {
        return (this.filteredQualificationDtos && this.filteredQualificationDtos.length > 0) || this.searchLocation;
    }

    doesExternalFilterPass(node: any): boolean {
        var passes = true;
        if (node.data) {
            if (this.filteredQualificationDtos && this.filteredQualificationDtos.length > 0) {
                passes = passes && this.filteredQualificationDtos.every((q) => {
                    var qualMatches = node.data.Qualifications.some((eq) => eq.Qualification.QualificationID === q.Qualification.QualificationID && (q.ExperienceLevel?.ExperienceLevelID == null || eq.ExperienceLevel.ExperienceLevelID === q.ExperienceLevel.ExperienceLevelID));
                    
                    return qualMatches;
                }); 
            }
            
            if (this.searchDistance > 0) {
                var distance = node.data.$HaversineDistance;
                passes = passes && (distance <= this.searchDistance);                
            }
        }
        return passes;
    }

    updateColumnVisiblity() {        
        this.gridApi.setColumnsVisible(["Qualifications.length"], false);
        this.gridApi.setColumnsVisible(["$FilteredQuals"], false);
        this.gridApi.setColumnsVisible(["$HaversineDistance"], false);

        if (this.filteredQualificationDtos.length > 0) {
            this.gridApi.setColumnsVisible(["$FilteredQuals"], true);
        }
        else {
            this.gridApi.setColumnsVisible(["Qualifications.length"], true);
        }

        if (this.searchLocation) {
            this.gridApi.setColumnsVisible(["$HaversineDistance"], true);
        }

    }

    filterQualificationColumn() {
        this.rowData.forEach((employee) => {
            employee.$FilteredQuals = employee.Qualifications.filter((q) => {
                return this.filteredQualificationDtos.some((eq) => eq.Qualification.QualificationID === q.Qualification.QualificationID);
            });
        }); 
    }

    clearFilters() {
        this.gridApi.setFilterModel(null);
        this.filteredQualificationDtos = [];
        this.searchLocation = null;
        this.searchDistance = -1;
        this.rowData.forEach((employee) => {
            employee.$HaversineDistance = null;
        });
        this.updateColumnVisiblity();
        this.cdr.markForCheck();
    }

    isFilterActive() {
        if (this.gridApi) {
            return this.gridApi.isAnyFilterPresent() || this.isExternalFilterPresent();
        }
        return this.isExternalFilterPresent();
    }
    
    filterQuals() {
        const dialogRef = this.dialog.open(QualificationFilterDialog, {
            data: {
                FilteredQualificationDtos: this.filteredQualificationDtos,
            },
        });

        return dialogRef.afterClosed().subscribe((dtos) => {
            if (dtos) {
                this.filteredQualificationDtos = dtos;
                this.filterQualificationColumn();
                this.updateColumnVisiblity();
                this.cdr.markForCheck();
            }
        });
    }

    filterLocation() {
        const dialogRef = this.dialog.open(LocationFilterDialog, {
            data: {
                location: this.searchLocation,
                distance: this.searchDistance,
            },
        });
        
        return dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                if (data.location) {
                    this.searchLocation = data.location;
                    this.searchDistance = data.distance;

                    this.rowData.forEach((employee) => {
                        employee.$HaversineDistance = this.haversineDistance(this.searchLocation.lat, this.searchLocation.lon, employee.ZipCodeLatitude, employee.ZipCodeLongitude);
                    }); 
            
                    this.gridApi!.applyColumnState({
                        state: [{ colId: "$HaversineDistance", sort: "asc" }],
                        defaultState: { sort: null },
                    });
                }
                else {
                    this.searchLocation = null;   
                    this.rowData.forEach((employee) => {
                        employee.$HaversineDistance = null;
                    });
                }
                this.updateColumnVisiblity();

                this.cdr.markForCheck();
            }
        });
    }

    haversineDistance(
        lat1: number,
        lon1: number,
        lat2: number,
        lon2: number,
        unit: 'km' | 'miles' = 'miles'
    ): number {
        const R = unit === 'km' ? 6371 : 3959; // Earth's radius in kilometers or miles
        
        const toRadians = (deg: number) => (deg * Math.PI) / 180;
        
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
        
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        
        return R * c;
    }
}
