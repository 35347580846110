import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceCategoryFormComponent } from '../shared/service-category-form/service-category-form.component';
import { CurrentServiceCategoryService } from 'src/app/services/current-service-category.service';
import { ServiceCategoryDto } from 'src/app/shared/generated/model/service-category-dto';
import { ServiceCategoryUpsertDto } from 'src/app/shared/generated/model/service-category-upsert-dto';

@Component({
    selector: 'talentbridge-service-category-create',
    templateUrl: './service-category-create.component.html',
    styleUrls: ['./service-category-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ServiceCategoryFormComponent
    ]  
})
export class ServiceCategoryCreateComponent implements OnInit, OnDestroy {
    @ViewChild("createNewServiceCategoryForm", { read: ServiceCategoryFormComponent }) form: ServiceCategoryFormComponent;

    public serviceCategory: ServiceCategoryDto = new ServiceCategoryDto();
    public serviceCategoryUpsertDto: ServiceCategoryUpsertDto;
    private serviceCategoryResponse: any;

    editMode = true;

    constructor(
        private currentServiceCategoryService: CurrentServiceCategoryService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.serviceCategoryUpsertDto = this.currentServiceCategoryService.createServiceCategoryDto(this.serviceCategory);
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    save(result): void {
        if (!!result) {
            this.serviceCategoryResponse = result;
            this.router.navigateByUrl(`service-categories/${result.ServiceCategoryID}`);
        } else {
            this.cdr.detectChanges();
        }
    }

    cancelEdit() {
        this.router.navigateByUrl("service-categories");
    }

    canExit() {
        if (this.serviceCategoryResponse) {
            return true;
        } else {
            return JSON.stringify(this.serviceCategoryUpsertDto) === JSON.stringify(this.form.serviceCategoryUpsertDto);
        }
    }
}
